import type { FC } from 'react';
import type { TagProps } from '../Tag.types';

import classnames from 'classnames';
import TagAndes from '@andes/tag';

import { SquareTag } from '../components/SquareTag/SquareTag';
import { Icon } from '../../Icon';

export const Tag: FC<TagProps> = (props) => {
  if ('shape' in props) {
    return <SquareTag {...props} />;
  }

  const { leftContent, bgColor, className, style, label, ...otherProps } =
    props;

  let icon;

  const ageRatingTypes = [
    'L',
    'AL',
    '10',
    'A10',
    'A12',
    '12',
    'A14',
    '14',
    'A16',
    '16',
    'A18',
    '18',
  ];

  const classNameTag = classnames('tag', className, {
    'tag--andes-bg-secondary': bgColor === undefined,
    'tag--bold': style === 'bold',
    'custom-tag-white': ageRatingTypes.includes(label),
  });

  if (leftContent && leftContent.type === 'icon') {
    icon = { icon: <Icon {...leftContent} /> };
  }

  return (
    <div style={{ background: bgColor }} className={classNameTag}>
      <TagAndes label={label} {...otherProps} avatar={icon} />
    </div>
  );
};
