import type { FC } from 'react';
import type { ComponentMapperProps } from '../../../components/ComponentMapper';
import type { FeedContainerProps } from '../../../components/Feed';

import { useEffect } from 'react';

import { ProviderTrackEventName, track } from '../../../utils/track';
import { TypeKnownComponent } from '../../../../types/components';
import { Feed } from '../../../components/Feed';
import { useFeedContainer } from '../hooks';
import { TypographyWrapper } from '../../../components/Feed/wrappers/TypographyWrapper';
import { CarouselSnapped } from './components/CarouselSnapped';
import commons from '../../../constants/commons.json';
import { FeedContentFinished } from '../../../components/FeedContentFinished';
import { MediaCardWrapper } from '../../../components/Feed/wrappers/MediaCardWrapper';
import { ErrorWrapper } from '../../../components/ErrorWrapper/ErrorWrapper';
import { CustomTitle } from '../../../@components/commons/CustomTittle';

const { DEFAULT_SCROLL_THRESHOLD_DESKTOP } = commons;

const componentsList: ComponentMapperProps['components'] = {
  [TypeKnownComponent.MEDIA_CARD]: MediaCardWrapper,
  [TypeKnownComponent.CAROUSEL]: CarouselSnapped,
  [TypeKnownComponent.TYPOGRAPHY]: TypographyWrapper,
  [TypeKnownComponent.TYPOGRAPHY_CUSTOM]: CustomTitle,
  [TypeKnownComponent.FEED_CONTENT_FINISHED]: FeedContentFinished,
  [TypeKnownComponent.ERROR_FEEDBACK]: ErrorWrapper,
};

export const FeedContainerHub: FC<FeedContainerProps> = ({
  className,
  components,
  tracks,
  nextPage = null,
}) => {
  const { feed, onNextPage, hasMore, isLoading } = useFeedContainer({
    feed: components,
    nextPage,
  });

  useEffect(() => {
    track(tracks, ProviderTrackEventName.DISPLAY);
  }, []);

  return (
    <Feed
      className={className}
      components={feed}
      componentsList={componentsList}
      onNextPage={onNextPage}
      scrollableTarget="body"
      scrollThreshold={DEFAULT_SCROLL_THRESHOLD_DESKTOP}
      isLoading={isLoading}
      hasMore={hasMore !== null}
      detectActiveElement
      activableTypes={[TypeKnownComponent.MEDIA_CARD]}
    />
  );
};
