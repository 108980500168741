import type { FC } from 'react';
import type { ButtonUIProps } from '../Button.type';

import { Button as ButtonAndes } from '@andes/button';

import { LinkButton } from './components/LinkButton';
import { AnchorButton } from './components/AnchorButton';

export const Button: FC<ButtonUIProps> = ({
  isSPA = true,
  label,
  innerRef,
  ...props
}) => {
  if (props.href && isSPA) {
    return <AnchorButton href={props.href} label={label} {...props} />;
  }

  if (props.to) {
    // @ts-expect-error force onClick prop
    return <LinkButton to={props.to} label={label} {...props} />;
  }

  return (
    <ButtonAndes innerRef={innerRef} {...props}>
      {label}
    </ButtonAndes>
  );
};
