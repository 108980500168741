import type { FC, SVGProps } from 'react';

export const UnLockedIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="2" y="2" width="12" height="12" rx="6" fill="white" />
    <path
      d="M6 10.6295V5.37052C6 4.98462 6.41861 4.74419 6.75193 4.93863L11.2596 7.56811C11.5904 7.76105 11.5904 8.23895 11.2596 8.43189L6.75194 11.0614C6.41861 11.2558 6 11.0154 6 10.6295Z"
      fill="black"
      fillOpacity="0.9"
    />
  </svg>
);
