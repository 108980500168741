import type { ParentalControlModalProps } from './ParentalControlModal.types';

import { useEffect } from 'react';

import { useI18n } from 'nordic/i18n';

import { useRestriction } from '../../../context/restrictions-store/restriction.store';
import { Restriction } from '../Restriction/Restriction';

export const ParentalControlModal = ({
  restricions,
  tracks,
  onContinue,
}: ParentalControlModalProps) => {
  const { setRestrictions } = useRestriction();
  const { i18n } = useI18n();

  useEffect(() => {
    if (restricions) {
      setRestrictions(restricions);
    }
  }, [restricions]);

  return (
    <Restriction
      title={i18n.gettext('¿Eres mayor de 18 años?')}
      titleCtaContinue={i18n.gettext('Si, soy mayor de 18')}
      titleCtaCancel={i18n.gettext('No, soy menor de 18')}
      closable={false}
      type="card"
      className="parental-control-modal"
      tracks={tracks}
      onContinue={onContinue}
    >
      <p>
        {i18n.gettext(
          'Mercado Play tiene contenido restringido para menores de 18.',
        )}
      </p>
      <p>
        <strong>
          {i18n.gettext(
            'Para acceder a todo el catálogo, tienes que ser mayor de edad.',
          )}
        </strong>
      </p>
    </Restriction>
  );
};
