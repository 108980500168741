import { useI18n } from 'nordic/i18n';

import { Message } from '../../../../../components/Message';

export const MaxQuotaMessage = () => {
  const { i18n } = useI18n();

  return (
    <Message
      hierarchy="quiet"
      icon="default"
      label={i18n.gettext(
        'Para seguir viendo el contenido gratis inicia sesión con tu cuenta.',
      )}
    />
  );
};
