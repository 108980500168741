import type { FC, SVGProps } from 'react';

export const LockedIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="12" height="12" rx="6" fill="white" />
    <path
      d="M4.69629 4.23454V5.26472H7.26772V4.23454C7.26772 2.56049 4.69629 2.56049 4.69629 4.23454Z"
      stroke="#1A1A1A"
    />
    <path
      d="M7.83929 5.26465H4.125V8.01597C4.125 8.56624 4.5377 8.97893 5.08796 8.97893H7.01389C7.56415 8.97893 7.83929 8.42867 7.83929 8.01597V5.26465Z"
      fill="#1A1A1A"
    />
    <path
      d="M4.125 5.26465V4.51465H3.375V5.26465H4.125ZM7.83929 5.26465H8.58929V4.51465H7.83929V5.26465ZM4.125 6.01465H7.83929V4.51465H4.125V6.01465ZM7.08929 5.26465V8.01597H8.58929V5.26465H7.08929ZM7.08929 8.01597C7.08929 8.03866 7.08502 8.07481 7.07209 8.11604C7.05928 8.15688 7.04213 8.18944 7.02638 8.21109C7.01111 8.23209 7.00285 8.23533 7.0073 8.23283C7.01517 8.2284 7.02126 8.22893 7.01389 8.22893V9.72893C7.57352 9.72893 7.98921 9.43748 8.23949 9.09334C8.47661 8.7673 8.58929 8.36972 8.58929 8.01597H7.08929ZM7.01389 8.22893H5.08796V9.72893H7.01389V8.22893ZM5.08796 8.22893C5.0054 8.22893 4.95734 8.20035 4.93046 8.17347C4.90358 8.14659 4.875 8.09854 4.875 8.01597H3.375C3.375 8.98045 4.12348 9.72893 5.08796 9.72893V8.22893ZM4.875 8.01597V5.26465H3.375V8.01597H4.875Z"
      fill="#1A1A1A"
    />
  </svg>
);
