import type { MelidataTrack } from './providers/melidata';

import merge from 'ts-deepmerge';

import { ProviderTrack, type Tracks } from './track.types';

export function inheritanceViewTrack(
  tracks: Tracks | undefined,
  track: MelidataTrack,
  customEventData?: (
    evtData: Record<string, unknown>,
  ) => Record<string, unknown>,
): MelidataTrack {
  const viewTracks = window.__VIEW_DISPLAY_TRACK__;
  const matchingTracks = [
    ...Object.values(viewTracks ?? {}),
    ...Object.values(tracks ?? {}),
  ]
    .map((it) => it[ProviderTrack.MELIDATA])
    .filter(
      (melidataTrack) =>
        melidataTrack && track.path.startsWith(`${melidataTrack.path}/`),
    )
    .sort((a, b) => b!.path.length - a!.path.length);

  let mergedData = track.event_data;

  matchingTracks.forEach((it) => {
    if (it?.event_data) {
      mergedData = merge(it.event_data, mergedData ?? {});
    }
  });

  if (!mergedData) {
    return track;
  }

  return {
    ...track,
    event_data: customEventData ? customEventData(mergedData) : mergedData,
  };
}
