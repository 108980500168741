import type { FC, SVGProps } from 'react';

export const CloseVCMIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    {...props}
  >
    <circle cx="15" cy="15.5" r="15" fill="black" fillOpacity="0.75" />
    <path
      d="M8.00586 9.55108L13.9548 15.5L8.00586 21.4489L9.05694 22.5L15.0059 16.5511L20.9439 22.4892L21.995 21.4381L16.0569 15.5L21.995 9.56192L20.9439 8.51083L15.0059 14.4489L9.05694 8.5L8.00586 9.55108Z"
      fill="white"
    />
  </svg>
);
