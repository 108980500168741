/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Duration } from '../../@schema';
import type { ViewProps } from '../../components/Page';

import snakecaseKeys from 'snakecase-keys';
import { z } from 'zod';

import { isCrawlerBot } from '../../server/middlewares/force-desktop-for-crawler-bots/force-desktop-for-crawler-bots';
import queryParams from '../../constants/query-params.json';
import COMMON_CONST from '../../constants/commons.json';

export const contentIdSchema = z.object({
  contentId: z.string().regex(/^[a-f0-9]{32}$/),
});

export function formatDuration(time?: Duration['format']): string | null {
  if (!time) {
    return null;
  }

  const { hours, minutes } = time;

  let str = '';

  if (hours) {
    str += `${hours}h `;
  }

  if (minutes) {
    str += `${minutes}min`;
  }

  return str;
}

export type ContentIdType = z.infer<typeof contentIdSchema>;

export namespace Utils {
  export const getPercentage = (
    value: number,
    maxValue: number,
    base = 100,
  ): number => Math.round((value * base) / maxValue);

  export const getArrayValuesFromObject = (
    object: Record<string, string>,
  ): Array<string> =>
    Array.from(new Set(Object.entries(object).map(([, value]) => value)));

  export const get = (
    object: Record<string, any>,
    path: string,
    defaultValue = '',
  ): string => {
    const pathArray = path.split('.');
    const result = pathArray.reduce(
      (acc, key) => (acc ? acc[key] : undefined),
      object,
    );

    return result !== undefined ? String(result) : defaultValue;
  };

  export const transformCamelCaseToSnakeCase = (
    input: Record<string, string>,
  ) => snakecaseKeys(input);

  export const getFilteredSearchParams = (searchParams: URLSearchParams) => {
    const requestedQueryParams = queryParams.map(({ name }) => name);
    const filteredParams: Record<string, string> = {};

    searchParams.forEach((_value, key) => {
      if (!requestedQueryParams.some((findKey) => key.startsWith(findKey))) {
        const value = searchParams.get(key);

        if (value !== null) {
          filteredParams[String(key)] = value;
        }
      }
    });

    return new URLSearchParams(filteredParams).toString();
  };

  export const removeOriginParams = () => {
    if (!window.location.search) {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const filteredSearch = getFilteredSearchParams(searchParams);

    const separator = filteredSearch.length ? '?' : '';

    window.history.replaceState(
      window.history.state,
      '',
      `${window.location.pathname}${separator}${filteredSearch || ''}`,
    );
  };

  export const validateId = ({ contentId }: ContentIdType) => {
    const id = contentId?.toString().trim();
    const parsedContentId = contentIdSchema.safeParse({ contentId: id });

    return parsedContentId;
  };

  export const formatPillLabel = (label: string, size: string): string => {
    if (size !== COMMON_CONST.VERTICAL_CARD_SMALL_SIZE) {
      return label;
    }

    const lowerCaseLabel = label.toLowerCase();
    const episodeAvailableRegexES = /episodio\s(\d+)\sdisponible/i;
    const episodeAvailableRegexPT = /episódio\s(\d+)\sdisponível/i;
    const newBadgeRegexES = /\b(nuevo|nueva)\b/i;
    const newBadgeRegexPT = /\b(novo|nova)\b/i;

    if (episodeAvailableRegexES.test(lowerCaseLabel)) {
      return label.replace(episodeAvailableRegexES, 'EPISODIO $1');
    }

    if (episodeAvailableRegexPT.test(lowerCaseLabel)) {
      return label.replace(episodeAvailableRegexPT, 'EPISÓDIO $1');
    }

    if (newBadgeRegexES.test(lowerCaseLabel)) {
      return 'NUEVO';
    }

    if (newBadgeRegexPT.test(lowerCaseLabel)) {
      return 'NOVO';
    }

    return label;
  };

  export const validateAppVersion = ({
    appVersion,
    minVersionApp,
  }: Record<string, string>): boolean => {
    const versionMinorApp = appVersion.split('.')[1] as unknown as number;
    const minVersionMinorApp = minVersionApp.split('.')[1] as unknown as number;

    return versionMinorApp >= minVersionMinorApp;
  };

  export const validateAllowList = (
    allowUsersList: Array<number> | undefined,
    userId: number | undefined,
  ) => allowUsersList && userId && allowUsersList.includes(userId);

  export const isVersionAppValid = (
    currentAppVersion: string | undefined,
    minVersionApp: string | null | undefined,
  ) => {
    if (!window?.MobileWebKit || !currentAppVersion) {
      return false;
    }

    return (
      minVersionApp &&
      validateAppVersion({
        appVersion: currentAppVersion,
        minVersionApp,
      })
    );
  };

  export const shouldShowVCMWhenIsLiveEvent = (
    currentAppVersion: string | undefined,
    minVersionApp: string | null | undefined,
    currentContentId: string,
    liveEventsIdsAllowVCM: Array<string> | undefined,
  ) => {
    if (
      !window?.MobileWebKit ||
      !currentAppVersion ||
      !currentContentId ||
      !liveEventsIdsAllowVCM
    ) {
      return false;
    }

    return (
      minVersionApp &&
      validateAppVersion({
        appVersion: currentAppVersion,
        minVersionApp,
      }) &&
      liveEventsIdsAllowVCM.includes(currentContentId)
    );
  };

  export const validateIfIsAbleToAddExperiment = ({
    currentAppVersion,
    minVersionApp,
    allowUsersList,
    userId,
  }: {
    currentAppVersion: string;
    minVersionApp: string;
    allowUsersList: Array<number>;
    userId?: number;
  }) =>
    window?.MobileWebKit &&
    isVersionAppValid(currentAppVersion, minVersionApp) &&
    !validateAllowList(allowUsersList, userId);

  export const transformKeys = (obj: Record<string, any>, target: string) =>
    Object.entries(obj).reduce(
      (newObjTransformed: typeof obj, [key, value]) => {
        if (key.includes(target)) {
          const newKey = key.replace(target, '').trim();

          newObjTransformed[newKey] = value;
        } else {
          newObjTransformed[key] = value;
        }

        return newObjTransformed;
      },
      {},
    );

  export const filterRestrictionsForCrawlers = (
    userAgent: string,
    data: ViewProps,
  ) => {
    const contextRestrictions = data?.configurations?.contextRestrictions;

    if (isCrawlerBot(userAgent) && contextRestrictions) {
      contextRestrictions.restrictions =
        contextRestrictions.restrictions.filter(
          (restriction) =>
            restriction !==
            COMMON_CONST.RESTRICTIONS_VALUES.CONTENT_ACCESS_MODAL,
        );
    }
  };
}

/**
 * Determines if a live event has ended based on the current status and end time.
 * @param {boolean} isLiveCurrent - Indicates whether the live event is currently active.
 * @param {number} liveEndTime - The end time of the event in seconds since the Unix epoch.
 * @returns {boolean} - Returns `true` if the event is not currently live and has already passed its end time.
 */
export const isFutureLive = (current: boolean, end_time: number) => {
  const now = new Date().getTime();
  // Gets the current timestamp in seconds since the Unix epoch
  const currentUnixTimestamp = Math.floor(now / 1000);

  return !current && end_time <= currentUnixTimestamp;
};

/**
 * * Converts a Unix timestamp (in seconds) into a formatted date-time string.
 *     Format: "day/abbr_month HH:MMh" (example: "12/ene 14:05h").
 *
 * @param {number} end_time Unix timestamp in seconds.
 * @returns {string} Formatted date-time string.
 */

export const DateAndTime = (end_time: number) => {
  const date = new Date(end_time * 1000);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const day = date.getDate();
  const month = date.toLocaleString('es-ES', { month: 'short' }).toLowerCase();
  const result = `${day}/${month} ${hours}:${minutes}h`;

  return result;
};
