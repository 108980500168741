import type { FC, SVGProps } from 'react';

export const Blocked: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.4813 12.9936C11.4813 13.8186 10.8125 14.4873 9.98752 14.4873C9.16254 14.4873 8.49377 13.8186 8.49377 12.9936C8.49377 12.1686 9.16254 11.4998 9.98752 11.4998C10.8125 11.4998 11.4813 12.1686 11.4813 12.9936Z"
      fill="black"
      fillOpacity={0.9}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5015 7.75017H14.5118V4.76174C14.5118 2.26998 12.4918 0.25 10 0.25C7.50825 0.25 5.48828 2.26998 5.48828 4.76174V7.75017H3.49854V15.9975C3.49854 18.0686 5.17747 19.7475 7.24853 19.7475H12.7515C14.8226 19.7475 16.5015 18.0686 16.5015 15.9975V7.75017ZM13.0118 4.76174V7.75017H6.98828V4.76174C6.98828 3.0984 8.33668 1.75 10 1.75C11.6634 1.75 13.0118 3.0984 13.0118 4.76174ZM4.99854 15.9975V9.25017H15.0015V15.9975C15.0015 17.2401 13.9941 18.2475 12.7515 18.2475H7.24853C6.00589 18.2475 4.99854 17.2401 4.99854 15.9975Z"
      fill="black"
      fillOpacity={0.9}
    />
  </svg>
);
