import type { TopLeftItemProps } from './TopLeftItem.types';

import classNames from 'classnames';

import { Pill } from '../../../Pill';
import { PillType } from '../../../PillTag/PillTag.types';
import COMMON_CONST from '../../../../constants/commons.json';

export const PillItemCards = ({
  itemProps,
  size,
  label,
  color,
  textColor,
}: TopLeftItemProps) => {
  const mergedItemProps = {
    ...itemProps,
    label: itemProps?.label || label!,
    color: itemProps?.color || color || COMMON_CONST.BADGE_COLOR,
    type: itemProps?.type || PillType.NEUTRAL,
    textColor: itemProps?.textColor || textColor,
  };

  return (
    <div
      className={classNames('mediacard__top-left-item', {
        'mediacard__top-left-item-exclusive':
          itemProps?.icon?.includes('mplay_blocked'),
      })}
    >
      <Pill
        roundedCorners={['bottom-right']}
        size={size}
        {...mergedItemProps}
      />
    </div>
  );
};
