import { MobileWebKit } from '../../@util/webview/mobile-webkit/mobile-webkit';

export const CUSTOM_NATIVE_EVENTS = {
  CUSTOM_BACK_EVENT: 'custom_back_event',
  FOOTER_EVENT: 'footer_event',
  BACK_VCP: 'back_vcp',
  BACK_CFS: 'back_cfs',
  BACK_SKINCAST: 'back_skincast',
  SEARCH_EVENT: 'search_event',
  USER_EVENT: 'user_event',
  PUSH_CFS_EVENT: 'push_cfs_event',
  USER_DID_LOGIN: 'user_did_login',
};

export const BACK_EVENTS_HUB_UPDATE = [
  CUSTOM_NATIVE_EVENTS.BACK_CFS,
  CUSTOM_NATIVE_EVENTS.BACK_VCP,
  CUSTOM_NATIVE_EVENTS.BACK_SKINCAST,
  CUSTOM_NATIVE_EVENTS.USER_DID_LOGIN,
];

export const BACK_EVENTS_VCM_UPDATE = [
  CUSTOM_NATIVE_EVENTS.BACK_CFS,
  CUSTOM_NATIVE_EVENTS.BACK_SKINCAST,
  CUSTOM_NATIVE_EVENTS.USER_DID_LOGIN,
];

export const handleNativeFooter = ({ show }: { show: boolean }) => {
  MobileWebKit.executeNative({
    event: CUSTOM_NATIVE_EVENTS.FOOTER_EVENT,
    args: {
      show,
    },
  });
};

export const handleNativeCustomBack = (value: boolean) => {
  const isIOS = window.MobileWebKit?.platform === 'ios';

  if (isIOS) {
    return;
  }

  MobileWebKit.executeNative({
    event: CUSTOM_NATIVE_EVENTS.CUSTOM_BACK_EVENT,
    args: {
      custom_back: value,
    },
  });
};

export const handleNativeSearch = () => {
  MobileWebKit.executeNative({
    event: CUSTOM_NATIVE_EVENTS.SEARCH_EVENT,
    args: { show: true },
  });
};

export const handleSkinCast = ({ deeplink }: { deeplink: string }) => {
  MobileWebKit.executeNative({
    event: CUSTOM_NATIVE_EVENTS.PUSH_CFS_EVENT,
    args: { deeplink },
  });
};
