export const MaskCarousel = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="136"
    height="204"
    viewBox="0 0 136 204"
    fill="#f5f5f5"
  >
    <path
      d="M0 8.75C0 4.33172 3.58172 0.75 8 0.75L128 0.75C132.418 0.75 136 4.33172 136 8.75L136 196.75C136 201.168 132.418 204.75 128 204.75L67.7436 204.75C61.1162 204.75 55.7436 199.377 55.7436 192.75V172.801C55.7436 163.965 48.5801 156.801 39.7436 156.801H12C5.37257 156.801 0 151.429 0 144.801L0 8.75Z"
      fill="inherit"
    />
  </svg>
);
