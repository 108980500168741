import type { FC } from 'react';
import type { AudioControlProps } from './AudioControl.types';

import { Icon } from '../../../../../Icon';
import { IconName } from '../../../../../Icon/Icon.type';

export const AudioControl: FC<AudioControlProps> = ({
  className,
  onClick,
  isMuted,
  title = 'button',
}) => (
  <button
    type="button"
    aria-label="audio-control"
    className={className}
    title={title}
    onClick={onClick}
  >
    <Icon
      content={isMuted ? IconName.VOLUME_OFF : IconName.VOLUME_ON}
      color="white"
    />
  </button>
);
