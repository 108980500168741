import type { FC, SVGProps } from 'react';

export const UnmuteVCMIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="11" cy="11.5" r="11" fill="black" fillOpacity="0.75" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.85714 13.6119L10.9121 16.387V6.61234L8.84338 9.40083H5.83711V13.6119H8.85714ZM8.31495 8.35083H4.78711V14.6619H8.32813L10.3839 17.4381C10.8859 18.1161 11.9621 17.761 11.9621 16.9174V6.08274C11.9621 5.23954 10.8868 4.88421 10.3844 5.5614L8.31495 8.35083Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3641 7.03647L14.6238 7.78104C15.5747 8.73125 16.1629 10.0444 16.1629 11.4949C16.1629 12.9408 15.5784 14.2501 14.6328 15.1996L15.3714 15.946C16.5093 14.8062 17.2129 13.2327 17.2129 11.4949C17.2129 9.75333 16.5063 8.17685 15.3641 7.03647Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8737 14.4724C14.6384 13.7118 15.1118 12.6585 15.1118 11.4947C15.1118 10.3427 14.6481 9.29909 13.8971 8.54032L13.1532 9.28135C13.715 9.85022 14.0618 10.6319 14.0618 11.4947C14.0618 12.3671 13.7071 13.1567 13.134 13.7272L13.8737 14.4724Z"
      fill="white"
    />
  </svg>
);
