import type { FC } from 'react';
import type { HeaderProps } from '../Header';

import { ProgressIndicator } from '../../../ProgressIndicator';
import commons from '../../../../constants/commons.json';

const { COLOR_BAR_LIVE } = commons;

export const ControlsCard: FC<Omit<HeaderProps, 'currentHeader'>> = ({
  header,
}) => {
  const hasControls =
    (header.default.background.props.ui?.controls?.length ?? 0) > 0;

  return hasControls ? (
    <ProgressIndicator
      className="media-card-video-player__progress"
      currentPercentage={100}
      color={COLOR_BAR_LIVE}
    />
  ) : null;
};
