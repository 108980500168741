import type { Context } from 'react';
import type { StaticDataProviderProps } from './static-data-context.types';

import { createContext, useContext } from 'react';

const defaultContext: StaticDataProviderProps = {
  version: null,
  redirectType: null,
  closeAllAction: null,
  VCMMaxHeight: null,
  device: null,
  isWebKit2: false,
  currentHost: '',
  scope: undefined,
  url: '',
  guest_id: '',
  siteId: '',
  timeZone: '',
  isKeepWatching: false,
  isVCMFromDeeplink: false,
  keyword: '',
};

export const StaticDataContext: Context<StaticDataProviderProps> =
  createContext<StaticDataProviderProps>(defaultContext);

export const { Provider: StaticDataProvider } = StaticDataContext;

export const useStaticData = () => useContext(StaticDataContext);
