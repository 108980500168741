import type { NextFunction, Request, RequestHandler, Response } from 'express';

export const CRAWLER_BOTS_REGEX =
  /(Googlebot|Googlebot-Video|Google-InspectionTool|AdsBot-Google|APIs-Google|GoogleOther|AdsBot-Google|Mediapartners-Google|APIs-Google|Googlebot-Image|facebookexternalhit|facebookcatalog|TwitterBot|Slackbot|Discordbot|WhatsApp|LinkedInBot|bingbot|TelegramBot)/i;

export const isCrawlerBot = (userAgent: string): boolean =>
  CRAWLER_BOTS_REGEX.test(userAgent);

export const forceDesktopForCrawlerBots: RequestHandler = (
  req: Request,
  res: Response,
  next: NextFunction,
) => {
  const isCrawler = CRAWLER_BOTS_REGEX.exec(req.get('User-Agent') || '');

  if (req?.device && isCrawler) {
    req.device.type = 'desktop';
    req.device.desktop = true;
    req.device.mobile = false;
    req.device.tablet = false;
  }

  next();
};
