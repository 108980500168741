import type { FC } from 'react';
import type { MediaCardFullProps } from './MediaCardFull.types';

import Card from '@andes/card';
import classname from 'classnames';

import { Link } from '../../../Link';
import { ContentSubtitle } from '../../../ContentSubtitle/mobile';
import { ContentTagList } from '../../../ContentTagList/mobile';
import { Image } from '../../../Image/mobile';
import { Typography } from '../../../Typography/mobile';
import { Banner } from '../../../Banner';
import { Header, HeaderType } from '../Header';
import {
  OutlineType,
  PaddingSizeDefault,
  ShadowType,
} from './MediaCardFull.types';
import CONSTANTS from '../../../../constants/commons.json';

const CardBody: FC<MediaCardFullProps> = ({
  currentHeader = HeaderType.DEFAULT,
  description,
  header,
  onCanPlay,
  preloadVideo = false,
}) => (
  <>
    <Header
      preloadVideo={preloadVideo}
      onCanPlay={onCanPlay}
      header={header}
      currentHeader={currentHeader}
    />

    <div className="mediacard__body">
      {description.image ? (
        <div className="mediacard__image-container">
          <Image
            {...description.image.props}
            className="mediacard__image-body"
          />
        </div>
      ) : null}

      <div className="mediacard__texts">
        <Typography
          component="h2"
          type="title"
          maxLines={2}
          className="mediacard__texts__title"
          size="xs"
          {...description.title.props}
        />

        <ContentSubtitle
          subtitles={description.subtitle}
          className="mediacard__texts__subtitle"
        />

        {description.tags ? (
          <ContentTagList className="mediacard__tag" tags={description.tags} />
        ) : null}
      </div>
    </div>

    {description.bottomBanner ? (
      <div className="mediacard__loyalty-footer-content">
        <Banner
          className="mediacard__loyalty-footer-content--banner"
          {...description.bottomBanner.props}
        />
      </div>
    ) : null}
  </>
);

export const MediaCardFull: FC<MediaCardFullProps> = (props) => {
  const {
    description,
    outline = OutlineType.DEFAULT,
    paddingSize = PaddingSizeDefault,
    shadow = outline === OutlineType.LIST
      ? ShadowType.ELEVATED
      : ShadowType.FLAT,
    linkTo,
    onMouseEnter,
    onMouseLeave,
    className,
    redirectAnchor,
    url,
  } = props;

  const extractContentIdFromUrl = (url: string): string => {
    try {
      const { pathname } = new URL(url, window.location.origin);
      const segments = pathname.split('/');
      const contentIdWithQuery = segments[3] || '';

      return contentIdWithQuery.split('?')[0];
    } catch {
      return '';
    }
  };

  const contentId = extractContentIdFromUrl(url || '');
  const IS_LIVE_EVENT = CONSTANTS.LIVE_EVENTS_ID;

  return (
    <Card
      shadow={shadow}
      paddingSize={paddingSize}
      className={classname(
        'mediacard',
        {
          'mediacard--outline-list': outline === OutlineType.LIST,
          'mediacard--loyalty-no-border': !!description.bottomBanner,
        },
        className,
      )}
    >
      {!redirectAnchor && !IS_LIVE_EVENT.includes(contentId) ? (
        <Link
          to={linkTo}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <CardBody {...props} />
        </Link>
      ) : (
        <a href={linkTo.pathname}>
          <CardBody {...props} />
        </a>
      )}
    </Card>
  );
};
