import { Component } from 'react';

import { LoggerFactory } from 'nordic/logger';

const logger = LoggerFactory('mplay-error-boundary');

type Props = {
  children: React.ReactNode;
  fallback: React.ReactNode;
};

class ErrorBoundary extends Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: { componentStack: string }) {
    logger.error(error.message, {
      componentStack: errorInfo.componentStack,
    });

    if (window.newrelic && typeof window.newrelic?.noticeError === 'function') {
      window?.newrelic?.noticeError(error, {
        componentStack: errorInfo.componentStack,
      });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children, fallback } = this.props;

    if (hasError) {
      return fallback;
    }

    return children;
  }
}

export default ErrorBoundary;
