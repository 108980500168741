import type { FC, SVGProps } from 'react';

export const StartOverVCMIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 0.5C7.39142 0.5 3.72429 3.12346 1.84615 7.34189V3.83333H0V10.6538H6.14839V8.80769H3.24064C4.7353 4.79983 7.91457 2.34615 12 2.34615C17.6078 2.34615 22.1538 6.89219 22.1538 12.5C22.1538 18.1078 17.6078 22.6538 12 22.6538C7.02264 22.6538 2.88174 19.0725 2.01358 14.3462H0.141132C1.02914 20.0971 6.00039 24.5 12 24.5C18.6274 24.5 24 19.1274 24 12.5C24 5.87258 18.6274 0.5 12 0.5Z"
      fill="white"
    />
  </svg>
);
