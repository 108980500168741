export const commonParams = [
  {
    key: 'use_web_title',
    value: 'false',
  },
  {
    key: 'back_action',
    value: 'close',
  },
  {
    key: 'back_style',
    value: 'arrow',
  },
  {
    key: 'swipe_gesture',
    value: 'false',
  },
  {
    key: 'authentication_mode',
    value: 'optional',
  },
  {
    key: 'search_mode',
    value: 'none',
  },
];

export const newVinculateParams = () => {
  const params = new URLSearchParams();

  commonParams.forEach((param) => {
    params.append(param.key, param.value);
  });
  params.append('title', 'Disney+');

  return params.toString();
};

export const level6Params = () => {
  const params = new URLSearchParams();

  commonParams.forEach((param) => {
    params.append(param.key, param.value);
  });
  params.append('title', 'Nivel 6');

  return params.toString();
};

export function withModalParams() {
  const params = new URLSearchParams();

  commonParams.forEach((param) => {
    params.append(param.key, param.value);
  });
  params.append('title', 'Nivel 6');
  params.append('showModal', 'true');
  params.append('product', 'meli_total');

  return params.toString();
}
