import type { FC } from 'react';
import type { ContentSearchProps } from './ContentSearch.type';

import { ContentSearch as BaseContentSearch } from '../../../ContentSearch';

export const ContentSearch: FC<ContentSearchProps> = ({
  data,
  platform,
  view,
}) => {
  if (!data) {
    return null;
  }

  return (
    <BaseContentSearch
      view={view}
      withoutBackArrow
      siteId={platform.siteId}
      siteDomain={platform.domain}
      {...data}
      text={view === 'SEARCH' ? data.text : ''}
    />
  );
};
