import type {
  HeaderBackgroundActiveProps,
  HeaderBackgroundDefaultProps,
  HeaderType,
} from '../Header';
import type { BannerProps } from '../../../Banner/Banner.types';
import type { LinkToProps } from '../../../../../types/globals';
import type { TagProps } from '@andes/tag';
import type { ImageProps } from '../../../Image/Image.types';
import type { TypographyApiProps } from '../../../Typography/mobile';
import type { Action } from '../../../MediaCardVertical/MediaCardVertical.types';
import type { ActionDialogBoxProps } from '../../../DialogBox/DialogBox.type';

export const enum ProgressBarType {
  FULL = 'full',
  LOADABLE = 'loadable',
}

export const enum ShadowType {
  ELEVATED = 'elevated',
  FLAT = 'flat',
  OUTLINE = 'outline',
}

export const enum OutlineType {
  DEFAULT = 'default',
  LIST = 'list',
}

export const enum PaddingSize {
  PADDING_0 = 0,
  PADDING_16 = 16,
  PADDING_24 = 24,
  PADDING_32 = 32,
}

export const PaddingSizeDefault = PaddingSize.PADDING_0;

export type MediaCardFullProps = {
  paddingSize?: PaddingSize;
  shadow?: ShadowType;
  outline?: OutlineType;
  muteDefault?: boolean;
  currentHeader?: HeaderType;
  url?: string;
  deeplink?: string;
  linkTo: LinkToProps;
  preloadVideo?: boolean;
  header: {
    default: HeaderBackgroundDefaultProps;
    active: HeaderBackgroundActiveProps;
  };
  description: {
    image?: {
      type: 'image';
      props: ImageProps;
    };
    title: {
      type: 'typography';
      props: TypographyApiProps;
    };
    subtitle: Array<{
      type: 'typography';
      props: TypographyApiProps;
    }>;
    tags?: Array<TagType>;
    bottomBanner?: {
      type: 'banner';
      props: BannerProps;
    };
  };
  onCanPlay?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  className?: string;
  isWebView?: boolean;
  action?: Action;
  redirectAnchor?: boolean;
  onClick?: (event?: Action | ActionDialogBoxProps | undefined) => void;
};

export type TagType = {
  type: 'tag';
  props: TagProps;
};
