import type { FC, MouseEventHandler } from 'react';
import type { AnchorButtonProps } from './AnchorButton.types';

import ButtonAndes from '@andes/button';
import { useHistory } from 'react-router-dom';

import CONSTANTS from '../../../../../constants/commons.json';

export const AnchorButton: FC<AnchorButtonProps> = ({ label, ...props }) => {
  const history = useHistory();

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    const contentIdInURL = window.location.pathname.split('/')[3];
    const IS_LIVE_EVENT = CONSTANTS.LIVE_EVENTS_ID;

    if (IS_LIVE_EVENT.includes(contentIdInURL)) {
      history.push(props.href);
    }

    if (!event.metaKey && !IS_LIVE_EVENT.includes(contentIdInURL)) {
      event.preventDefault();
      history.push(props.href);
    }

    props.onClick?.(event);
  };

  return (
    <ButtonAndes {...props} onClick={handleClick}>
      {label}
    </ButtonAndes>
  );
};
