export const ChevronDown = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      fillOpacity="0.8"
    >
      <polygon
        className="seasons-dropdown__svg-fill"
        fillRule="nonzero"
        transform="translate(10.000000, 10.000000) rotate(-90.000000) translate(-10.000000, -10.000000)"
        points="8.4482158 9.99750228 13.243845 5.20187305 12.3953169 4.35334491 6.75115953 9.99750228 12.4003123 15.6466551 13.2488405 14.798127"
      />
    </g>
  </svg>
);

export const ChevronUP = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      fillOpacity="0.8"
    >
      <polygon
        className="seasons-dropdown__svg-fill"
        fillRule="nonzero"
        transform="translate(10.001873, 9.995000) rotate(90.000000) translate(-10.001873, -9.995000)"
        points="8.45008885 9.99250228 13.2457181 5.19687305 12.3971899 4.34834491 6.75303257 9.99250228 12.4021854 15.6416551 13.2507135 14.793127"
      />
    </g>
  </svg>
);
