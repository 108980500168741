import type { RequestNativeEventArgs } from '@meli/mobile-webkit/types/events/native-event';

import { CUSTOM_NATIVE_EVENTS } from './custom-native-events';

export const isValidBackEvent = (args: unknown, backEvents: Array<string>) => {
  const isValidArgs = typeof args === 'object' && args !== null;
  const hasValidAction =
    isValidArgs && 'action' in args && typeof args.action === 'string';

  return {
    isAbleToReload:
      hasValidAction && backEvents.includes(args.action as string),
    userDidLogin:
      hasValidAction &&
      backEvents.includes(args.action as string) &&
      args.action === CUSTOM_NATIVE_EVENTS.USER_DID_LOGIN,
  };
};

export const backEventHandlerWebKit2 = ({
  event,
  callbacks,
  backEvents,
}: {
  event: CustomEvent<RequestNativeEventArgs<unknown>>;
  callbacks: {
    secondCallback: (isABleToReload?: boolean) => void;
    reload?: () => void;
  };
  backEvents: Array<string>;
}) => {
  const { reload, secondCallback } = callbacks;
  const { args } = event.detail;
  const { isAbleToReload, userDidLogin } = isValidBackEvent(args, backEvents);

  if (isAbleToReload) {
    secondCallback(isAbleToReload);
  }

  if (userDidLogin) {
    reload?.();
  }
};
