import type { MeliPlayerRef, PlayerViewMode } from './MeliPlayer.types';
import type { VTTCue } from './vtt.types';

export enum PlayerEvent {
  ALL = 'ALL',
  ERROR = 'ERROR',
  INIT = 'INIT',
  MUTED = 'MUTED',
  UNMUTED = 'UNMUTED',
  READY = 'READY',
  PLAY = 'PLAY',
  PLAYING = 'PLAYING',
  PAUSED = 'PAUSED',
  STALL_STARTED = 'STALL_STARTED',
  STALL_ENDED = 'STALL_ENDED',
  PLAYBACK_FINISHED = 'PLAYBACK_FINISHED',
  TIME_CHANGED = 'TIME_CHANGED',
  SEEK = 'SEEK',
  SEEKED = 'SEEKED',
  SOURCE_LOADING = 'SOURCE_LOADING',
  SOURCE_LOADED = 'SOURCE_LOADED',
  VIEW_MODE_CHANGED = 'VIEW_MODE_CHANGED',
  SEGMENT_REQUEST_FINISHED = 'SEGMENT_REQUEST_FINISHED',
  VOLUME_CHANGED = 'VOLUME_CHANGED',
  AUTOPLAY_FAILURE = 'AUTOPLAY_FAILURE',
  CUE_ENTER = 'CUE_ENTER',
  CUE_EXIT = 'CUE_EXIT',
  SUBTITLE_ADDED = 'SUBTITLE_ADDED',
  SUBTITLE_REMOVED = 'SUBTITLE_REMOVED',
  SUBTITLE_ENABLED = 'SUBTITLE_ENABLED',
  SUBTITLE_DISABLED = 'SUBTITLE_DISABLED',
  AD_BREAK_STARTED = 'AD_BREAK_STARTED',
  AD_BREAK_FINISHED = 'AD_BREAK_FINISHED',
  AD_STARTED = 'AD_STARTED',
  AD_FINISHED = 'AD_FINISHED',
  AD_SKIPPED = 'AD_SKIPPED',
  AD_PAUSED = 'AD_PAUSED',
  AD_RESUMED = 'AD_RESUMED',
  AUDIO_ADDED = 'AUDIO_ADDED',
  AUDIO_REMOVED = 'AUDIO_REMOVED',
  AUDIO_ENABLED = 'AUDIO_ENABLED',
  AD_MANIFEST_LOADED = 'AD_MANIFEST_LOADED',
  METADATA = 'METADATA',
}

export type BaseEvent<T extends PlayerEvent = PlayerEvent> = {
  type: T;
  timestamp: number;
  player: MeliPlayerRef<unknown>;
};

export type TimeChangedEvent = BaseEvent<PlayerEvent.TIME_CHANGED> & {
  currentTime: number;
  issuer?: IssuerEventType;
};

export type SeekEvent = BaseEvent<PlayerEvent.SEEK | PlayerEvent.SEEKED> & {
  currentTime: number;
};

export type SourceLoadedEvent = BaseEvent<PlayerEvent.SOURCE_LOADED> & {
  totalDuration: number;
};

export type VolumeChangedEvent = BaseEvent<PlayerEvent.VOLUME_CHANGED> & {
  from: number;
  to: number;
};

export type SegmentRequestFinisedEvent =
  BaseEvent<PlayerEvent.SEGMENT_REQUEST_FINISHED> & {
    maxBufferedTime: number;
  };

export type ViewModeChangedEvent = BaseEvent<PlayerEvent.VIEW_MODE_CHANGED> & {
  from: PlayerViewMode;
  to: PlayerViewMode;
};

export type MetadataEvent = BaseEvent<PlayerEvent.METADATA> & {
  type?: string;
  timestamp?: number;
  metadataType?: string;
  metadata?: {
    content: string;
    schemeIdUri: string;
  };
  start?: number;
  end?: number;
};

type BaseAdEvent = {
  id?: string;
  creative?: {
    id?: string;
    adId?: string;
  };
  duration: number;
  skippable: boolean;
  skipOffset: number | null;
  position: number;
};

export type AdSkippedEvent = BaseAdEvent & {
  skippedAt: number;
};

export type AdPausedEvent = BaseAdEvent & {
  pausedAt: number;
};

export type AdEvent<T = BaseAdEvent> = BaseEvent & {
  ad: T;
};

export type CueData = {
  subtitleId: string;
  start: number;
  end: number;
  text: string;
  html?: string;
  vtt?: VTTCue;
};

export type CueEvent = BaseEvent<PlayerEvent.CUE_ENTER | PlayerEvent.CUE_EXIT> &
  CueData;

export type SubtitleEvent = BaseEvent<
  PlayerEvent.SUBTITLE_ADDED | PlayerEvent.SUBTITLE_REMOVED
> & {
  subtitle: SubtitleTrack;
};

export type SubtitleTrack = {
  id: string;
  lang: string;
  label: string;
};

export type AudioTrack = {
  id: string;
  lang: string;
  label: string;
};

export type AudioEvent = BaseEvent<
  PlayerEvent.AUDIO_ADDED | PlayerEvent.AUDIO_REMOVED
> & {
  track: AudioTrack;
};

export type ErrorEvent = BaseEvent<PlayerEvent.ERROR> & {
  name: string;
  message?: string;
};

export const enum IssuerEventType {
  API = 'api',
  ADVERTISING_API = 'advertising-api',
  MELIPLAYER = 'meliplayer',
}

export type BaseUserInteractionEvent = BaseEvent<
  PlayerEvent.PAUSED | PlayerEvent.PLAYING
> & {
  issuer?: IssuerEventType;
};

type StrictExclude<T, U> = T extends U ? (U extends T ? never : T) : T;

type TypedEvents = StrictExclude<
  BasePlayerEventMap[keyof BasePlayerEventMap],
  BaseEvent
>;

export type AllPlayerEvents =
  | TypedEvents
  | (Omit<BaseEvent, 'type'> & {
      type: Exclude<PlayerEvent, TypedEvents['type']>;
    });

type BasePlayerEventMap = {
  [PlayerEvent.INIT]: BaseEvent;
  [PlayerEvent.READY]: BaseEvent;
  [PlayerEvent.PLAY]: BaseEvent;
  [PlayerEvent.PLAYING]: BaseUserInteractionEvent;
  [PlayerEvent.PAUSED]: BaseUserInteractionEvent;
  [PlayerEvent.STALL_STARTED]: BaseEvent;
  [PlayerEvent.STALL_ENDED]: BaseEvent;
  [PlayerEvent.PLAYBACK_FINISHED]: BaseEvent;
  [PlayerEvent.MUTED]: BaseEvent;
  [PlayerEvent.UNMUTED]: BaseEvent;
  [PlayerEvent.TIME_CHANGED]: TimeChangedEvent;
  [PlayerEvent.SEEK]: SeekEvent;
  [PlayerEvent.SEEKED]: SeekEvent;
  [PlayerEvent.SOURCE_LOADING]: BaseEvent;
  [PlayerEvent.SOURCE_LOADED]: SourceLoadedEvent;
  [PlayerEvent.VIEW_MODE_CHANGED]: ViewModeChangedEvent;
  [PlayerEvent.SEGMENT_REQUEST_FINISHED]: SegmentRequestFinisedEvent;
  [PlayerEvent.VOLUME_CHANGED]: VolumeChangedEvent;
  [PlayerEvent.AUTOPLAY_FAILURE]: BaseEvent;
  [PlayerEvent.CUE_ENTER]: CueEvent;
  [PlayerEvent.CUE_EXIT]: CueEvent;
  [PlayerEvent.SUBTITLE_ADDED]: SubtitleEvent;
  [PlayerEvent.SUBTITLE_REMOVED]: SubtitleEvent;
  [PlayerEvent.SUBTITLE_ENABLED]: SubtitleEvent;
  [PlayerEvent.SUBTITLE_DISABLED]: SubtitleEvent;
  [PlayerEvent.AD_BREAK_STARTED]: BaseEvent;
  [PlayerEvent.AD_BREAK_FINISHED]: BaseEvent;
  [PlayerEvent.AD_STARTED]: AdEvent;
  [PlayerEvent.AD_FINISHED]: AdEvent;
  [PlayerEvent.AD_SKIPPED]: AdEvent<AdSkippedEvent>;
  [PlayerEvent.AD_PAUSED]: AdEvent<AdPausedEvent>;
  [PlayerEvent.AD_RESUMED]: AdEvent;
  [PlayerEvent.AUDIO_ADDED]: AudioEvent;
  [PlayerEvent.AUDIO_REMOVED]: AudioEvent;
  [PlayerEvent.AUDIO_ENABLED]: AudioEvent;
  [PlayerEvent.AD_MANIFEST_LOADED]: BaseEvent;
  [PlayerEvent.METADATA]: MetadataEvent;
  [PlayerEvent.ERROR]: ErrorEvent;
};

export type PlayerEventMap = BasePlayerEventMap & {
  [PlayerEvent.ALL]: AllPlayerEvents;
};

export type PlayerEventCallback<T extends PlayerEvent> = (
  event: PlayerEventMap[T],
) => unknown;

export type PlayerEventExtraData<T extends PlayerEvent> = Omit<
  PlayerEventMap[T],
  keyof BaseEvent
>;
