import type { FC, SVGProps } from 'react';

export const MuteVCMIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="11" cy="11.5" r="11" fill="black" fillOpacity="0.75" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.40444 8.35669L4.58594 6.54017L5.33052 5.79478L16.6927 17.1446L15.9482 17.89L12.051 13.9971V16.9232C12.051 17.7668 10.9749 18.1219 10.4728 17.444L8.41706 14.6678H4.87604V8.35669H6.40444ZM11.001 12.9483V16.3929L8.94607 13.6178H5.92604V9.40669H7.45559L11.001 12.9483Z"
      fill="white"
    />
    <path
      d="M8.7116 7.94191L9.46251 8.69201L11.001 6.6182V10.2289L12.051 11.2777V6.0886C12.051 5.2454 10.9757 4.89007 10.4733 5.56726L8.7116 7.94191Z"
      fill="white"
    />
    <path
      d="M15.4535 7.04309L14.7134 7.78788C15.6637 8.73801 16.2516 10.0507 16.2516 11.5008C16.2516 12.6366 15.8908 13.6883 15.2777 14.5474L16.0267 15.3011C16.8269 14.2447 17.3016 12.9282 17.3016 11.5008C17.3016 9.7596 16.5952 8.18342 15.4535 7.04309Z"
      fill="white"
    />
    <path
      d="M14.4936 13.8336C14.9402 13.1663 15.2006 12.3639 15.2006 11.5006C15.2006 10.3378 14.7281 9.28533 13.9645 8.52483L13.2206 9.26589C13.795 9.83647 14.1506 10.627 14.1506 11.5006C14.1506 12.0663 14.0015 12.5972 13.7404 13.056L14.4936 13.8336Z"
      fill="white"
    />
  </svg>
);
