import { create } from 'zustand';

import commons from '../../constants/commons.json';
import { restClient } from '../../common/rest-client';
import api from '../../constants/api.json';

const { RESTRICTIONS_VALUES } = commons;
const { PATH_API } = api;

export type RestrictionConfirmation = 'accepted' | 'rejected';

export type ContentAccessConfirmation = {
  content_access_confirmation: RestrictionConfirmation;
};

export type RestrictionStoreState = {
  restrictions: Array<string>;
  setRestrictions: (restrictions: Array<string>) => void;
  request: (payload: ContentAccessConfirmation) => Promise<boolean>;
  snackbarVisible: boolean;
  setSnackbarVisible: (show: boolean) => void;
  isAbleToCheckRestrictions: boolean;
  modalValue?: RestrictionConfirmation;
  firstRestriction: string | undefined;
  permission: {
    isModalOpen: boolean;
    isContentLocked: boolean;
    nbcuUserGuest: boolean;
    nbcuUserLoggedIn: boolean;
    hasNbcuRestriction: boolean;
    hasRatingAge: boolean;
    userGuestParentalControl: boolean;
    userGuestDefault: boolean;
    maxQuotaRestriction: boolean;
    userGuestRestriction: boolean;
    hasGuestUserRestriction: boolean;
    hasPlayerRestriction: boolean;
  };
  isAbleToRenderCta: boolean;
  setIsAbleToRenderCta: (value: boolean) => void;
  isOpenModalLock: boolean;
  setIsOpenModalLock: (isOpenModalLock: boolean) => void;
};

const removeContentAccessModalRestriction = (restrictions: Array<string>) =>
  restrictions.filter(
    (restriction) => restriction !== RESTRICTIONS_VALUES.CONTENT_ACCESS_MODAL,
  );

const updatedRestrictions = (restrictions: Array<string>) => {
  const withoutContentAccessModalRestriction =
    removeContentAccessModalRestriction(restrictions);

  return withoutContentAccessModalRestriction;
};
const getFirstRestrictionWithoutModal = (restrictions: Array<string>) => {
  if (restrictions.includes(RESTRICTIONS_VALUES.CONTENT_ACCESS_MODAL)) {
    return restrictions[1];
  }

  return restrictions[0];
};

const computePermissions = (
  restrictions: Array<string>,
  firstRestriction: string | undefined,
) => ({
  isModalOpen: restrictions.includes(RESTRICTIONS_VALUES.CONTENT_ACCESS_MODAL),
  isContentLocked:
    restrictions.includes(RESTRICTIONS_VALUES.AGE_RATING) ||
    restrictions.includes(RESTRICTIONS_VALUES.USER_GUEST_PARENTAL_CONTROL) ||
    restrictions.includes(RESTRICTIONS_VALUES.CONTENT_ACCESS_MODAL),
  nbcuUserGuest: restrictions.includes(
    RESTRICTIONS_VALUES.USER_GUEST_LOCK_CONTENT,
  ),
  nbcuUserLoggedIn: restrictions.includes(
    RESTRICTIONS_VALUES.USER_LOCK_BY_AUDIENCES,
  ),
  hasNbcuRestriction:
    restrictions.includes(RESTRICTIONS_VALUES.USER_GUEST_LOCK_CONTENT) ||
    restrictions.includes(RESTRICTIONS_VALUES.USER_LOCK_BY_AUDIENCES),
  hasRatingAge: firstRestriction === RESTRICTIONS_VALUES.AGE_RATING,
  userGuestParentalControl:
    firstRestriction === RESTRICTIONS_VALUES.USER_GUEST_PARENTAL_CONTROL,
  userGuestDefault: firstRestriction === RESTRICTIONS_VALUES.USER_GUEST_DEFAULT,
  maxQuotaRestriction:
    firstRestriction === RESTRICTIONS_VALUES.USER_GUEST_QUOTA_MAX_CONTENT,
  userGuestRestriction: firstRestriction === RESTRICTIONS_VALUES.USER_GUEST,
  hasGuestUserRestriction:
    firstRestriction === RESTRICTIONS_VALUES.USER_GUEST_DEFAULT ||
    firstRestriction === RESTRICTIONS_VALUES.USER_GUEST_PARENTAL_CONTROL ||
    firstRestriction === RESTRICTIONS_VALUES.USER_GUEST,
  hasPlayerRestriction:
    restrictions.includes(RESTRICTIONS_VALUES.AGE_RATING) ||
    restrictions.includes(RESTRICTIONS_VALUES.USER_GUEST_PARENTAL_CONTROL) ||
    restrictions.includes(RESTRICTIONS_VALUES.CONTENT_ACCESS_MODAL) ||
    restrictions.includes(RESTRICTIONS_VALUES.USER_GUEST_LOCK_CONTENT) ||
    restrictions.includes(RESTRICTIONS_VALUES.USER_LOCK_BY_AUDIENCES),
});

export const useRestriction = create<RestrictionStoreState>((set) => ({
  restrictions: [],
  snackbarVisible: false,
  isAbleToCheckRestrictions: false,
  modalValue: undefined,
  firstRestriction: undefined,
  permission: {
    isModalOpen: false,
    isContentLocked: false,
    nbcuUserGuest: false,
    nbcuUserLoggedIn: false,
    hasNbcuRestriction: false,
    hasRatingAge: false,
    userGuestParentalControl: false,
    userGuestDefault: false,
    maxQuotaRestriction: false,
    userGuestRestriction: false,
    hasGuestUserRestriction: false,
    hasPlayerRestriction: false,
  },
  isAbleToRenderCta: false,
  isOpenModalLock: false,
  setIsOpenModalLock: () =>
    set((state) => ({ isOpenModalLock: !state.isOpenModalLock })),
  setRestrictions: (restrictions: Array<string>) => {
    const firstRestriction = getFirstRestrictionWithoutModal(restrictions);
    const permission = computePermissions(restrictions, firstRestriction);

    set((state) => ({
      ...state,
      isAbleToCheckRestrictions: false,
      firstRestriction,
      permission,
      restrictions,
    }));
  },
  setSnackbarVisible: (visible) => {
    set(() => ({
      snackbarVisible: visible,
    }));
  },
  request: async (payload) => {
    try {
      await restClient.put(`${PATH_API.USER_PREFERENCES}`, {
        data: payload,
      });

      set((state) => ({
        ...state,
        isAbleToCheckRestrictions: true,
        restrictions: updatedRestrictions(state.restrictions),
        permission: computePermissions(
          updatedRestrictions(state.restrictions),
          state.firstRestriction,
        ),
        modalValue: payload.content_access_confirmation,
      }));

      return true;
    } catch (error) {
      set((state) => ({
        ...state,
        snackbarVisible: true,
        isAbleToCheckRestrictions: true,
        restrictions: state.restrictions.filter(
          (restriction) =>
            restriction !== commons.RESTRICTIONS_VALUES.CONTENT_ACCESS_MODAL,
        ),
        permission: computePermissions(
          state.restrictions.filter(
            (restriction) =>
              restriction !== commons.RESTRICTIONS_VALUES.CONTENT_ACCESS_MODAL,
          ),
          state.firstRestriction,
        ),
        modalValue: 'rejected',
      }));

      return false;
    }
  },
  setIsAbleToRenderCta: (value) =>
    set((state) => ({
      ...state,
      isAbleToRenderCta: value,
    })),
}));
