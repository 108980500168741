import { useCallback } from 'react';

import { isWebKit2 } from './check-version';
import { useStaticData } from '../../context';
import { MobileWebKit } from '../../@util/webview';

const MPLAY_PREFIX = 'meli://mplay';
const REDIRECT_HOST = `${MPLAY_PREFIX}-redirect`;
const CFS_HOST = `${MPLAY_PREFIX}/cfs`;
const VCP_HOST = `${MPLAY_PREFIX}/vcp`;

const handlePush = (href: string) => {
  MobileWebKit.push({ url: href });
};

export const useCustomDeeplinkHandler = () => {
  const { device } = useStaticData();

  const isIOS = device?.nativeApp?.os === 'iOS';

  const customNavigationHandler = useCallback(
    (href: string) => {
      if (!isWebKit2()) {
        handlePush(href);

        return;
      }

      const isNavigatingToCFS = href.includes(CFS_HOST);
      const isNavigatingToVCP = href.includes(VCP_HOST);

      if (isIOS && (isNavigatingToCFS || isNavigatingToVCP)) {
        const finalHref = href.replace(MPLAY_PREFIX, REDIRECT_HOST);

        handlePush(finalHref);

        return;
      }

      handlePush(href);
    },
    [isIOS],
  );

  return customNavigationHandler;
};
