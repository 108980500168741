import type { ContextValue } from '../../../../../../pages/hub/context';
import type {
  ComponentCarousel,
  Components,
  OptionList,
  UpdateKeepWatching,
} from './useKeepWatching.type';
import type {
  ActionDialogBoxProps,
  DeleteInProgressActionProps,
} from '../../../../../../components/DialogBox/DialogBox.type';
import type { MediaCardCompactComponent } from '../../../../../../components/Carousel';
import type { TracksPlatform } from '../../../../../../utils/track';

import { useContext, useState, useCallback } from 'react';

import commons from '../../../../../../constants/commons.json';
import {
  ProviderTrack,
  ProviderTrackEventName,
  sendPlatformTracks,
} from '../../../../../../utils/track';
import {
  getOptionsKeepWatching,
  removeFromKeepWatching,
  currentKeepWatching,
} from '../../../../repositories';
import { HubContext } from '../../../../../../pages/hub/context';
import { TypeKnownComponent } from '../../../../../../../types/components';
import { SharedContext } from '../../../../../../context/shared-context/shared.context';
import {
  handleNativeCustomBack,
  handleNativeFooter,
} from '../../../../../../utils/webkit/custom-native-events';
import { useCustomNativeBackEvent } from '../../../../../../hooks/useCustomBackNativeEvent/useCustomBackNativeEvent';
import { useStaticData } from '../../../../../../context';
import { Utils } from '../../../../../../utils';
import { useFullVCMNavigation } from '../../../../../../@domain/hooks/useFullVCMNavigation';
import { useCustomDeeplinkHandler } from '../../../../../../utils/webkit/custom-deeplink-handler';

const optionsId: Record<string, string> = {
  delete: 'delete_in_progress',
  navigation: 'more_info_in_progress',
};
const carouselKwId = 'keep-watching';
const { VARIANT_FULL_VCM } = commons;

export const useKeepWatching = (components: Array<Components>) => {
  const [id, setId] = useState<string>();
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [carouselComponents, setCarouselComponents] =
    useState<Array<Components>>(components);
  const [loading, setLoading] = useState<boolean>(false);
  const [optionLists, setOptionLists] = useState<OptionList>();
  const context = useContext(SharedContext);
  const { minVersionApp, allowUsersList, userId, experiment } = useStaticData();
  const { navigateToVCM, navigateToCFS } = useFullVCMNavigation();
  const customDeepLinkHandler = useCustomDeeplinkHandler();

  const {
    getSnackbarById,
    setShowOverlayLoading,
    setComponentsOverlayLoading,
    data,
    nextPage,
    setInitialData,
  } = useContext<ContextValue>(HubContext);

  const closeOverlay = useCallback(() => {
    setOverlayVisible(false);
    setLoading(false);
    handleNativeFooter({ show: true });
    handleNativeCustomBack(false);
  }, []);

  useCustomNativeBackEvent(closeOverlay);

  const openOverlay = useCallback(() => {
    handleNativeFooter({ show: false });
    setOverlayVisible(true);
    handleNativeCustomBack(true);
  }, []);

  const resetStates = () => {
    closeOverlay();
    setId(undefined);
    setOptionLists(undefined);
  };

  const getTracks = (type: string) => {
    const track = optionLists?.props.optionsList.props.options.find(
      ({ id }) => id === optionsId[type],
    )?.tracks;

    return { ...optionLists?.tracks, ...track };
  };

  const getComponentsLoadingOverlay = (options: OptionList) => {
    const option = options.props.optionsList.props.options.find(
      ({ id }) => id === optionsId.delete,
    );
    const { loadingLabel, spinner } =
      option?.props as DeleteInProgressActionProps;

    setComponentsOverlayLoading({ loadingLabel, spinner });
  };

  const getMenuOptions = async (contentId: string) => {
    openOverlay();
    getSnackbarById('delete-content-kw-success-snackbar')?.hideSnackbar();
    getSnackbarById('delete-content-kw-error-snackbar')?.hideSnackbar();
    setId(contentId);
    setLoading(true);

    try {
      const response: OptionList = await getOptionsKeepWatching(
        contentId,
        context,
      );

      getComponentsLoadingOverlay(response);
      setOptionLists(response);

      const trackBasedOnPlatform = window.MobileWebKit
        ? response.tracks?.display?.[ProviderTrack.MELIDATA]?.event_data
        : response.tracks;

      sendPlatformTracks({
        typeEvent:
          response.tracks?.display?.[ProviderTrack.MELIDATA]?.type || 'view',
        path: response.tracks?.display?.[ProviderTrack.MELIDATA]?.path || '',
        tracks: trackBasedOnPlatform,
        trackEventName: ProviderTrackEventName.DISPLAY,
      });
      setLoading(false);
    } catch (error: unknown) {
      getSnackbarById('delete-content-kw-error-snackbar')?.showSnackbar();
      resetStates();
    }
  };

  const removeKwComponent = () => {
    const idxKwComponent = data?.findIndex(
      (component) => (component as ComponentCarousel).props.id === carouselKwId,
    );

    let newFeed = data?.filter(
      (component) => (component as ComponentCarousel).props.id !== carouselKwId,
    );

    if (idxKwComponent) {
      newFeed = newFeed?.filter((_, idx) => idx !== idxKwComponent - 1);
    }

    setInitialData({ data: newFeed || [], nextPage });
  };

  const removeContentFromKeepWatchingById = async (
    action: ActionDialogBoxProps,
  ) => {
    getSnackbarById('delete-content-kw-success-snackbar')?.hideSnackbar();
    getSnackbarById('delete-content-kw-error-snackbar')?.hideSnackbar();

    try {
      setShowOverlayLoading(true);
      closeOverlay();

      const { contentId } = action;
      const response: UpdateKeepWatching = await removeFromKeepWatching(
        contentId,
        context,
      );
      const carouselComponents = response.props.components.filter(
        ({ type }) => type === TypeKnownComponent.CAROUSEL,
      )[0] as ComponentCarousel;

      if (!carouselComponents && data) {
        removeKwComponent();
      }

      getSnackbarById('delete-content-kw-success-snackbar')?.showSnackbar();
      setCarouselComponents(
        (carouselComponents?.props
          ?.components as Array<MediaCardCompactComponent>) || [],
      );
      setShowOverlayLoading(false);
    } catch (error: unknown) {
      setShowOverlayLoading(false);
      getSnackbarById('delete-content-kw-error-snackbar')?.showSnackbar();
      resetStates();
    }
  };

  const handleNavigationToNative = (
    deeplink: ActionDialogBoxProps['deeplink'],
  ) => {
    customDeepLinkHandler(deeplink!);
  };

  const validateFlowDepedingOnExperiment = (action: ActionDialogBoxProps) => {
    if (
      Utils.validateAllowList(allowUsersList, userId) ||
      experiment?.variantId === VARIANT_FULL_VCM
    ) {
      navigateToVCM({
        ...action,
        contentId: id,
      });

      return;
    }

    handleNavigationToNative(action.deeplink);
  };

  const validateFlowDependingOnVersionApp = (action: ActionDialogBoxProps) => {
    if (
      Utils.isVersionAppValid(
        window.MobileWebKit?.platformInfo.appVersion?.version,
        minVersionApp,
      )
    ) {
      validateFlowDepedingOnExperiment(action);
    } else {
      handleNavigationToNative(action.deeplink);
    }

    closeOverlay();
  };

  const handleSelectOption = (action: ActionDialogBoxProps) => {
    const dataTracks = {
      typeEvent:
        getTracks(action.type).select?.[ProviderTrack.MELIDATA]?.type || 'view',
      path: getTracks(action.type).select?.[ProviderTrack.MELIDATA]?.path || '',
      eventData: {
        ...(getTracks(action.type).select?.[ProviderTrack.MELIDATA]
          ?.event_data as TracksPlatform['eventData']),
        ...(getTracks(action.type).display?.[ProviderTrack.MELIDATA]
          ?.event_data as TracksPlatform['eventData']),
      },
      tracks: getTracks(action.type),
      trackEventName: ProviderTrackEventName.SELECT,
    };

    if (action.type === 'delete') {
      removeContentFromKeepWatchingById(action);
    }

    if (action.type === 'navigation') {
      validateFlowDependingOnVersionApp(action);
    }

    sendPlatformTracks(dataTracks);
  };

  const getCurrentKeepWatching = useCallback(async () => {
    const response: UpdateKeepWatching = await currentKeepWatching(context);

    const carouselComponents = response.props.components.filter(
      ({ type }) => type === TypeKnownComponent.CAROUSEL,
    )[0] as ComponentCarousel;

    if (!carouselComponents) {
      return;
    }

    setCarouselComponents(
      (carouselComponents?.props
        ?.components as Array<MediaCardCompactComponent>) || [],
    );
  }, [context]);

  const onVisibleChange = (visible: boolean) => {
    handleNativeFooter({ show: !visible });
    setOverlayVisible(visible);

    if (!visible) {
      setOptionLists(undefined);
    }

    handleNativeCustomBack(visible);
  };

  const handleClickOnKwCard = ({
    deeplink,
    contentId,
  }: {
    deeplink: string | undefined;
    contentId: string | undefined;
  }) => {
    if (
      !Utils.isVersionAppValid(
        window.MobileWebKit?.platformInfo.appVersion?.version,
        minVersionApp,
      )
    ) {
      handleNavigationToNative(deeplink);

      return;
    }

    if (!deeplink || !contentId) {
      return;
    }

    if (
      Utils.validateAllowList(allowUsersList, userId) ||
      experiment?.variantId === VARIANT_FULL_VCM
    ) {
      const action: ActionDialogBoxProps = {
        type: 'navigation',
        deeplink,
      };

      navigateToVCM({ ...action, contentId });
      setTimeout(() => {
        navigateToCFS({
          contentId,
          deeplink,
        });
      }, 100);

      return;
    }

    handleNavigationToNative(deeplink);
  };

  return {
    title: optionLists?.props.title.props.label || '',
    overlayVisible,
    getMenuOptions,
    loading,
    optionLists: optionLists?.props.optionsList,
    handleSelectOption,
    onVisibleChange,
    carouselComponents,
    getCurrentKeepWatching,
    removeKwComponent,
    validateFlowDependingOnVersionApp,
    handleClickOnKwCard,
    handleNavigationToNative,
  };
};
