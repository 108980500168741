import { useEffect, useRef } from 'react';

export const useScrollTool = (currentRef: React.RefObject<HTMLElement>) => {
  const isRightButtonClickedFirstTime = useRef(false);

  useEffect(() => {
    let leftButton: HTMLElement | null = null;
    let rightButton: HTMLElement | null = null;
    let handleClick: () => void;

    const timer = setTimeout(() => {
      const scrollToolRef = currentRef.current?.children[1];
      const leftScrollToolFade = currentRef.current
        ?.previousElementSibling as HTMLElement;
      const rightScrollToolFade = scrollToolRef?.firstChild as HTMLElement;

      leftButton = scrollToolRef?.lastChild?.firstChild as HTMLElement;
      rightButton = scrollToolRef?.lastChild?.lastChild as HTMLElement;

      const handleScroll = (
        button: HTMLElement | null,
        checkScrollCondition: (
          scrollLeft: number,
          clientWidth: number,
          scrollWidth: number,
        ) => boolean,
        displayStyleLeftFade: string,
        displayStyleRightFade: string,
        isRightButton = true,
      ) => {
        if (!button) {
          return;
        }

        handleClick = () => {
          let scrollInterval: NodeJS.Timeout;

          const checkScroll = () => {
            const firstChild = currentRef.current?.firstChild as HTMLElement;

            if (firstChild) {
              const { scrollLeft, scrollWidth, clientWidth } = firstChild;

              if (isRightButtonClickedFirstTime.current !== isRightButton) {
                isRightButtonClickedFirstTime.current = isRightButton;
                leftScrollToolFade.style.display = displayStyleLeftFade;
              }

              if (checkScrollCondition(scrollLeft, clientWidth, scrollWidth)) {
                rightScrollToolFade.style.display = displayStyleRightFade;
                clearInterval(scrollInterval);
              }
            }
          };

          scrollInterval = setInterval(checkScroll, 100);
        };

        button.addEventListener('click', handleClick);
      };

      if (rightButton) {
        handleScroll(
          rightButton,
          (scrollLeft, clientWidth, scrollWidth) =>
            Math.abs(scrollLeft + clientWidth - scrollWidth) <= 1,
          'block',
          'none',
        );
      }

      if (leftButton) {
        handleScroll(
          leftButton,
          (scrollLeft, clientWidth, scrollWidth) =>
            Math.abs(scrollLeft + clientWidth - scrollWidth) > 1,
          'none',
          'block',
          false,
        );
      }
    }, 0);

    return () => {
      if (leftButton) {
        leftButton.removeEventListener('click', handleClick);
      }

      if (rightButton) {
        rightButton.removeEventListener('click', handleClick);
      }

      clearTimeout(timer);
    };
  }, []);
};
