import type { ChangeEvent } from 'react';
import type { ContentSearchProps } from './ContentSearch.types';
import type { SubHeaderProps } from '../SubHeader/desktop/SubHeader.type';

import { useState, useEffect, useRef } from 'react';

import Search20 from '@andes/icons/Search20';
import ArrowLeft20 from '@andes/icons/ArrowLeft20';
import classNames from 'classnames';

export const ContentSearch: React.FC<
  ContentSearchProps & Pick<SubHeaderProps, 'view'>
> = ({
  platformId = 'MPLAY',
  siteId = 'MLA',
  siteDomain = 'mercadolibre.com.ar',
  placeholder,
  placeholderActive,
  text,
  endpointSuggestion = `https://http2.mlstatic.com/suggestions/mplay/${siteId}`,
  useCustomPlatformContext = true,
  withOutline = false,
  withoutBackArrow = false,
  maxLength,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [textInput, setTextInput] = useState(text);
  const [hasLoaded, setHasLoaded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handlerChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    const textSanetized = e.target.value.replace(/<[^>]+>|[<>[\]{}/|]/g, '');

    setTextInput(textSanetized);
  };

  const searchBoxScriptAlreadyExists = () =>
    document.querySelector('script#search-box') !== null;

  useEffect(() => {
    if (searchBoxScriptAlreadyExists()) {
      setHasLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (hasLoaded && window.Searchbox) {
      // eslint-disable-next-line no-new
      new window.Searchbox('.nav-search-input', {
        platformId,
        siteId,
        siteDomain,
        loggedIn: true,
        limit: 10,
        searchEndpoint: '/buscar/$query?origin=internal',
        endpointSuggestion,
        useCustomPlatformContext,
      });
    }
  }, [hasLoaded]);

  return (
    <section className="content-search" ref={containerRef}>
      <form className="nav-search">
        <div className="content-search-icon">
          {isFocused && !withoutBackArrow ? (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div data-testid="arrowleft" onClick={() => setIsFocused(false)}>
              <ArrowLeft20 />
            </div>
          ) : (
            <div data-testid="search">
              <Search20 />
            </div>
          )}
        </div>
        <input
          type="text"
          className={classNames('nav-search-input', 'content-search-input', {
            'content-search-input--outline': withOutline,
          })}
          spellCheck="false"
          placeholder={isFocused ? placeholderActive : placeholder}
          value={textInput}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={handlerChangeValue}
          maxLength={maxLength}
        />
      </form>
    </section>
  );
};
