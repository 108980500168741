import type { PillProps } from './Pill.types';

import { Pill as PillAndes } from '@andes/badge';
import classNames from 'classnames';

import { Icon } from '../Icon';

export const Pill: React.FC<PillProps> = ({
  label,
  color,
  textColor,
  type,
  icon,
  className,
  ...props
}) => (
  <PillAndes
    {...props}
    className={classNames(className, {
      'left-pill-exclusive': icon?.includes('mplay_blocked'),
    })}
    secondaryColor={{
      background: color || '',
      text: textColor,
    }}
  >
    {icon && <Icon content={icon} className="pill-icon" />}
    <span>{label}</span>
  </PillAndes>
);
