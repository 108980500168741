import type { IFilterXSSOptions } from 'xss';
import type { TypographyCustomProps } from '../../../components/Typography/Typography.types';

import xss from 'xss';
import parser from 'html-react-parser';
import { Title } from '@andes/typography';
import classNames from 'classnames';

import { defaultOptionsXss } from '../../../components/Typography/Typography';

export const CustomTitle: React.FC<TypographyCustomProps> = ({
  optionsXSS = defaultOptionsXss,
  ...props
}): JSX.Element => {
  const options: IFilterXSSOptions = optionsXSS;
  const components = props?.components || [];

  const sanitizedCustomLabel = (label: string) => parser(xss(label, options));

  return (
    <>
      {components.map((component) => {
        if (component.props && component.props.size === 'xl') {
          const { label, size } = component.props;

          return (
            <Title
              className={classNames({
                'title-custom-xl': size === 'xl',
              })}
              key={component.props.label}
              component="h4"
              size={size}
            >
              {sanitizedCustomLabel(label)}
            </Title>
          );
        }

        if (component.props && component.props.size) {
          const { label } = component.props;

          return (
            <Title
              className="title-custom"
              key={component.props.label}
              component="h4"
              size="m"
            >
              {sanitizedCustomLabel(label)}
            </Title>
          );
        }

        if (component.props && !component.props.size) {
          const { label, size } = component.props;

          return (
            <Title
              className={classNames({
                'title-custom-xs': !size,
              })}
              key={component.props.label}
              component="h4"
              size="xs"
            >
              {sanitizedCustomLabel(label)}
            </Title>
          );
        }

        return null;
      })}
    </>
  );
};
