import type { FC } from 'react';
import type { HeaderProps } from './Header.types';
import type { ContextValue } from '../../../../pages/hub/context';

import { useEffect, useState, useCallback, useContext, useRef } from 'react';

import { v4 as uuid } from 'uuid';
import classnames from 'classnames';

import { Image } from '../../../Image/mobile';
import { Button } from '../../../Button/mobile';
import { Pill } from '../../../Pill';
import { MediaCardVideoPlayer } from '../MediaCardVideoPlayer';
import { HeaderType } from './Header.types';
import {
  ProviderTrack,
  ProviderTrackEventName,
  getRealFilterTrack,
  track,
} from '../../../../utils/track';
import { HubContext } from '../../../../pages/hub/context';
import { PillTag } from '../../../PillTag';
import { PillBorder } from '../../../PillTag/PillTag.types';
import { TracksContext } from '../../../../context/tracks-context/tracks.context';
import { ControlsCard } from '../ControlsCard/ControlsCard';
import { PillItemCards } from '../PillItemCards';

export const Header: FC<HeaderProps> = ({
  header,
  currentHeader,
  preloadVideo = false,
  onCanPlay,
}) => {
  const playbackSessionIdRef = useRef(uuid());
  const [reachedEnd, setReachedEnd] = useState<boolean>(false);
  const [playing, setPlaying] = useState<boolean>(false);
  const { filter } = useContext<ContextValue>(HubContext);
  const { ableToSendFilterTrack, setValueFilterTrack } =
    useContext(TracksContext);

  useEffect(() => {
    playbackSessionIdRef.current = uuid();

    if (currentHeader === HeaderType.DEFAULT) {
      setReachedEnd(false);
      setPlaying(false);
    }
  }, [currentHeader]);

  const sendTracks = (
    trackName: ProviderTrackEventName,
    customEventData?: (
      evtData: Record<string, unknown>,
    ) => Record<string, unknown>,
  ) => {
    delete header.active?.background.tracks?.display?.[ProviderTrack.MELIDATA]
      ?.event_data?.origin;
    setValueFilterTrack();

    let tracks: Record<string, string> = {
      playbackSessionId: playbackSessionIdRef.current,
    };

    if (ableToSendFilterTrack) {
      tracks = {
        filter: getRealFilterTrack(filter),
        ...tracks,
      };
    }

    track(
      header.active?.background.tracks,
      trackName,
      tracks,
      undefined,
      customEventData,
    );
  };

  const handlePlaying = () => {
    setPlaying(true);
    sendTracks(ProviderTrackEventName.PLAYBACK_START);
  };

  const handlePlaybackFinished = useCallback(() => {
    setReachedEnd(true);
    sendTracks(ProviderTrackEventName.PLAYBACK_END);
  }, [currentHeader]);

  return (
    <div className="mediacard__header" data-testid="header">
      <div
        data-testid={`header-${currentHeader}`}
        className={classnames(
          'mediacard__header-content mediacard__header-content--visible',
          {
            'mediacard__header--with-overlay': reachedEnd,
          },
        )}
      >
        {!reachedEnd && header.default.topLeftItem && (
          <PillItemCards itemProps={header.default.topLeftItem.props} />
        )}

        {!reachedEnd &&
        header.active?.background.type === 'player' &&
        header.active.background.props.ui.topLeftItem ? (
          <div className="mediacard__top-left-item">
            <Pill
              roundedCorners={['bottom-right']}
              {...header.active.background.props.ui.topLeftItem.props}
            />
          </div>
        ) : null}
        {!reachedEnd && header.default.topRightItem ? (
          <div className="mediacard__top-right-item">
            <PillTag
              border={PillBorder.STANDARD}
              {...header.default.topRightItem.props}
              className="mediacard__top-right-item__pill-tag"
            />
          </div>
        ) : null}
        {header.default.bottomLeftItem ? (
          <div className="mediacard__bottom-left-item">
            <PillTag
              border={PillBorder.STANDARD}
              {...header.default.bottomLeftItem.props}
            />
          </div>
        ) : null}
        {!reachedEnd &&
        header.active?.background.type === 'player' &&
        header.active.background.props.ui.topRightItem?.props.label ? (
          <div className="mediacard__top-right-item">
            <PillTag
              border={PillBorder.STANDARD}
              {...header.active.background.props.ui.topRightItem.props}
              className="mediacard__top-right-item__pill-tag"
            />
          </div>
        ) : null}
        {reachedEnd ? (
          <Button
            className="mediacard__overlay-button"
            hierarchy="quiet"
            size="small"
            label={
              header.active?.background.props.ui?.finishedOverlay
                ?.buttonLabel || ''
            }
          />
        ) : null}
        <Image
          className={classnames('mediacard__image', {
            'mediacard__header--playing':
              playing && currentHeader === HeaderType.ACTIVE,
          })}
          ratio="16_9"
          {...header.default.background.props}
        />
        {(preloadVideo || currentHeader === HeaderType.ACTIVE) &&
        !reachedEnd &&
        header.active &&
        header.active.background.type !== 'image' ? (
          <MediaCardVideoPlayer
            className="mediacard__header--video-player"
            data-testid="header-video"
            playVideo={currentHeader === HeaderType.ACTIVE}
            onReady={onCanPlay}
            onPlaying={handlePlaying}
            onPlaybackFinished={handlePlaybackFinished}
            playing={playing}
            tracks={header.active.background.tracks}
            {...header.active.background.props}
          />
        ) : null}
        <ControlsCard header={header} />
      </div>
    </div>
  );
};
