import type { MessageProps } from './Message.types';

import { Message as MessageAndes } from '@andes/message';
import classnames from 'classnames';

const namespace = 'andes-custom-message';

export const Message = ({
  color = 'accent',
  label,
  hierarchy = 'loud',
}: MessageProps) => (
  <MessageAndes
    className={classnames(`${namespace}`, {
      [`${namespace}--no-icon`]: color === 'magenta',
      [`${namespace}__color--magenta`]: color === 'magenta',
    })}
    hierarchy={hierarchy}
    color={color === 'magenta' ? 'accent' : color}
  >
    {label}
  </MessageAndes>
);
