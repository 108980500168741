import { useI18n } from 'nordic/i18n';

import { Message } from '../../../../../components/Message';

export const RatingAgeMessage = () => {
  const { i18n } = useI18n();

  return (
    <Message
      color="orange"
      hierarchy="loud"
      icon="default"
      label={i18n.gettext(
        'Este contenido no está disponible para personas de tu edad.',
      )}
    />
  );
};
