import type { FC, SVGProps } from 'react';

export const PauseVCMIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="8.75"
      y="5.25"
      width="2.5"
      height="22.5"
      fill="white"
      stroke="white"
      strokeWidth="1.5"
    />
    <rect
      x="20.75"
      y="5.25"
      width="2.5"
      height="22.5"
      fill="white"
      stroke="white"
      strokeWidth="1.5"
    />
  </svg>
);
