import type {
  OptionsTrack,
  ProviderTrackEventName,
  Track,
  TrackProviderType,
  Tracks,
} from './track.types';
import type { DataTrack, MelidataTrack } from './providers/melidata';
import type { ExperimentType } from '../../context';

import { LoggerFactory } from 'nordic/logger';
import camelCase from 'lodash/camelCase';

import * as trackProviders from './providers';
import { ProviderTrack } from './track.types';
import { inheritanceViewTrack } from './inheritance';
import { restClient } from '../../common/rest-client';
import { isWebKit2 } from '../webkit/check-version';

const log = LoggerFactory('mplay-track');

export const validateEnvironment = () => process.env.NODE_ENV === 'development';

const postTrack = (type: 'event' | 'view', dataTrack: DataTrack) => {
  restClient.post('/realtime/track', {
    params: {
      requestType: 'melidata',
    },
    data: {
      type,
      ...dataTrack,
    },
    timeout: 10000,
  });
};

export const track = <T extends Tracks, K extends keyof T>(
  tracks: T | undefined,
  event: K,
  additionalData?: Record<string, unknown>,
  { withViewTrack = true }: OptionsTrack = {},
  customEventData?: (
    evtData: Record<string, unknown>,
  ) => Record<string, unknown>,
): void => {
  try {
    const eventTrack = tracks?.[event] as Track | undefined;

    if (!eventTrack) {
      return;
    }

    Object.entries(eventTrack).forEach(([trackProvider, trackProviderData]) => {
      if (
        !Object.values(ProviderTrack).includes(trackProvider as ProviderTrack)
      ) {
        return log.warn('Track provider not supported.', { trackProvider });
      }

      if (trackProviderData) {
        const trackProviderFunction = (trackProviders as TrackProviderType)[
          camelCase(trackProvider)
        ];

        const inheritanceTrack = withViewTrack
          ? inheritanceViewTrack(
              tracks,
              trackProviderData as MelidataTrack,
              customEventData,
            )
          : trackProviderData;

        return trackProviderFunction(
          inheritanceTrack,
          additionalData,
          validateEnvironment(),
        );
      }

      return null;
    });
  } catch (error: unknown) {
    log.error(error as string);
  }
};

type ContentItem = {
  content_type: string;
  content_id: string;
  content_tags: Array<string>;
  type: string;
};

export type TracksPlatform = {
  tracks?: Tracks;
  eventData?: Record<string, Array<ContentItem> | number | string>;
  typeEvent: 'event' | 'view';
  path: string;
  trackEventName?: ProviderTrackEventName;
  parentTracks?: ProviderTrackEventName;
  experiments?: Record<string, unknown>;
};

export type TrackingEvent<T> = {
  eventData?: T & {
    experiment?: ExperimentType;
  };
  typeEvent: 'event' | 'view';
  path: string;
};

export const sendPlatformTracks = (payload: TracksPlatform) => {
  const { tracks, typeEvent, path, eventData, trackEventName, experiments } =
    payload;
  const isAbleToSendTracks = window.melidata && window.MobileWebKit;

  let viewTracks = {};

  if (tracks && isAbleToSendTracks) {
    const { change, display, select, ...restOfTracks } = tracks;

    viewTracks = restOfTracks;
  }

  if (isAbleToSendTracks) {
    // Envia tracks serverSide temporalmente para webview
    postTrack(typeEvent, {
      path,
      data: { ...viewTracks, ...eventData },
      experiments,
    });

    if (!isWebKit2()) {
      window?.melidata?.('send', typeEvent, {
        path,
        event_data: { ...viewTracks, ...eventData },
      });
    }
  }

  if (!window.MobileWebKit && !isAbleToSendTracks) {
    track(tracks, trackEventName!, {
      ...eventData,
    });
  }
};

export const sendTrack = <T>(
  payload: Pick<TrackingEvent<T>, 'eventData' | 'path' | 'typeEvent'>,
) => {
  const { typeEvent, eventData, path } = payload;

  if (eventData?.experiment) {
    const trackData = {};
    const typeEventForExperiment =
      typeEvent === 'view' ? 'createViewTrack' : 'createEventTrack';
    const { experiment, ...restEventData } = eventData;

    window?.melidata?.(typeEventForExperiment, trackData);
    window?.melidata?.('withPath', path, trackData);
    window?.melidata?.('withData', restEventData || {}, trackData);

    window?.melidata?.(
      'withExperiment',
      experiment.variantName,
      Number(experiment.variantId),
      trackData,
    );

    // @ts-expect-error: ignoring because ts does not infer well
    window?.melidata?.('sendTrack', trackData);
  } else {
    window?.melidata?.('cleanAndSend', typeEvent, {
      path,
      event_data: eventData,
    } as any);
  }
};
