/* eslint-disable @typescript-eslint/method-signature-style */
import type { AdapterInitializer } from './adapters/adapters.types';
import type { ControlsLayerProps } from './components/layers/ControlsLayer';
import type { SettingsOverlayProps } from './components/controls/SettingsOverlay';
import type { ComponentType, ReactNode, RefObject } from 'react';
import type {
  AudioTrack,
  CueData,
  IssuerEventType,
  PlayerEvent,
  PlayerEventCallback,
  SubtitleTrack,
} from './events.types';
import type { ImageProps } from '../Image/Image.types';
import type { OverlayProps } from '../../container/ContentLiveContainer/type/ComponentLive.types';
import type { ProviderTrackEventName, Track } from '../../utils/track';

/**
 * This configuration propagates to the adapters.
 */
export type BaseConfig = {
  muted?: boolean;
  autoplay?: boolean;
  defaultSubtitleTrack?: string;
  defaultAudioTrack?: Array<string>;
  ads?: AdsConfig;
  poster?: string;
  analytics?: {
    key?: string;
    title: string | undefined;
    videoId: string | undefined;
    userId: string | undefined;
  };
};

export type AdsConfig =
  | {
      strategy: 'vast';
      strategies?: Array<string>;
      vast: {
        url: string;
      };
      lang?: string;
      vmap?: {
        url: string;
      };
      awsMediaTailorLive?: AwsMediaTailorLive;
    }
  | {
      strategy: 'vmap';
      strategies?: Array<string>;
      vmap: {
        url: string;
      };
      lang?: string;
      vast?: {
        url: string;
      };
      awsMediaTailorLive?: AwsMediaTailorLive;
    };

export interface AwsMediaTailorLive {
  strategy: string;
  clientSideTracking?: ClientSideTracking;
}

export interface ClientSideTracking {
  cdnPrefixUrl: string;
  hlsSessionInitUrl: string;
  dashSessionInitUrl: string;
  bodyPayload: BodyPayload;
  reportingMode: string;
  adSignalingEnabled: boolean;
}

export interface BodyPayload {
  adSignaling: AdSignaling;
  adsParams: AdsParams;
}

export interface AdSignaling {
  enabled: boolean;
}

export interface AdsParams {
  custParams: string;
  correlator: string;
  descriptionUrl: string;
  isGuest: string;
  tfcd: string;
  iu: string;
  ppid: string;
}

export type ResponseMediaTailor = {
  manifestUrl: string;
  trackingUrl: string;
};

export interface Extension {
  content: string;
  type: string;
}

export interface TrackingEvent {
  beaconUrls: Array<string>;
  duration: number;
  durationInSeconds: number;
  eventId: string;
  eventProgramDateTime: null;
  eventType: string;
  startTime: string;
  startTimeInSeconds: number;
  ad?: AdEvent;
  creativeId?: string;
  creativeSequence?: number | string;
}

export interface Ad {
  adId: string;
  adParameters: string;
  adProgramDateTime: null;
  adSystem: string;
  adTitle: string;
  adVerifications: Array<any>;
  companionAds: Array<any>;
  creativeId: string;
  creativeSequence: string;
  duration: string;
  durationInSeconds: number;
  extensions: Array<Extension>;
  mediaFiles: null;
  skipOffset: string | null;
  startTime: string;
  startTimeInSeconds: number;
  trackingEvents: Array<TrackingEvent>;
  vastAdId: string;
}

export type Avail = {
  adBreakTrackingEvents: Array<any>;
  adMarkerDuration: string;
  ads: Array<Ad>;
  availId: string;
  availProgramDateTime: null;
  duration: string;
  durationInSeconds: number;
  meta: null;
  nonLinearAdsList: Array<any>;
  startTime: string;
  startTimeInSeconds: number;
};

export type TrackingInfo = {
  avails: Array<Avail>;
};

export type trackingDataType = {
  isPlayingAds: boolean;
  trackingInfo: TrackingInfo;
  availabilityStartTime: number;
  availabilityStartTimeInSeconds: number;
  currentTime: number | undefined;
  playerTimeSyncOffset: number;
  eventTrackings: Array<TrackingEvent>;
  dispatchedTrackingEvents: Set<string>;
  resultClienSideTrackingData:
    | {
        trackingURL: string;
        manifestUrl: string;
      }
    | undefined;
  tracks: Partial<Record<ProviderTrackEventName, Track>> | undefined;
};

export interface AdEvent {
  adId: string;
  adTitle: string;
}

export type MeliPlayerListeners = {
  onInit?: PlayerEventCallback<PlayerEvent.INIT>;
  onSourceLoading?: PlayerEventCallback<PlayerEvent.SOURCE_LOADING>;
  onSourceLoaded?: PlayerEventCallback<PlayerEvent.SOURCE_LOADED>;
  onPlaying?: PlayerEventCallback<PlayerEvent.PLAYING>;
  onPlaybackFinished?: PlayerEventCallback<PlayerEvent.PLAYBACK_FINISHED>;
  onReady?: PlayerEventCallback<PlayerEvent.READY>;
  onTimeChanged?: PlayerEventCallback<PlayerEvent.TIME_CHANGED>;
  onSeeked?: PlayerEventCallback<PlayerEvent.SEEKED>;
  onViewModeChanged?: PlayerEventCallback<PlayerEvent.VIEW_MODE_CHANGED>;
  onPaused?: PlayerEventCallback<PlayerEvent.PAUSED>;
  onAdBreakStarted?: PlayerEventCallback<PlayerEvent.AD_BREAK_STARTED>;
  onAdBreakFinished?: PlayerEventCallback<PlayerEvent.AD_BREAK_FINISHED>;
  onAdStarted?: PlayerEventCallback<PlayerEvent.AD_STARTED>;
  onAdFinished?: PlayerEventCallback<PlayerEvent.AD_FINISHED>;
  onAdSkipped?: PlayerEventCallback<PlayerEvent.AD_SKIPPED>;
  onAdPaused?: PlayerEventCallback<PlayerEvent.AD_PAUSED>;
  onAdResumed?: PlayerEventCallback<PlayerEvent.AD_RESUMED>;
  onError?: PlayerEventCallback<PlayerEvent.ERROR>;
  onAll?: PlayerEventCallback<PlayerEvent.ALL>;
};

export type MeliPlayerComponentsMap = {
  ControlsLayer: ComponentType<ControlsLayerProps>;
  ErrorLayer: ComponentType;
  SettingsOverlay: ComponentType<SettingsOverlayProps>;
  Overlay: ComponentType<OverlayProps>;
  EndedLayer?: ComponentType;
};

export type MeliPlayerProps<T = unknown> = MeliPlayerListeners & {
  adapter: AdapterInitializer<T> | Promise<AdapterInitializer<T>>;
  adapterConfig: T;
  config?: BaseConfig;
  source?: SourceConfig;
  className?: string;
  children?: ReactNode;
  components?: Partial<MeliPlayerComponentsMap>;
  translations: MeliPlayerTranslations;
  startOnFullscreen?: boolean;
};

export type MeliPlayerRef<T> = {
  get: () => IPlayerAdapter<T>;
};

export enum PlayerStatus {
  /** Player is waiting a source to load. */
  IDLE = 'IDLE',

  /** Player is loading metadata and prebuffering. */
  LOADING = 'LOADING',

  /** Player has enough data to start playback. */
  READY = 'READY',

  /** Playback is in progress. */
  PLAYING = 'PLAYING',

  /** Playback is paused. */
  PAUSED = 'PAUSED',

  /** Playback reached the end of the source. */
  ENDED = 'ENDED',

  /** Player crashed by an error */
  ERROR = 'ERROR',
}

export enum PlayerViewMode {
  INLINE = 'INLINE',
  FULLSCREEN = 'FULLSCREEN',
  PICTURE_IN_PICTURE = 'PICTURE_IN_PICTURE',
}

export enum StreamTypes {
  LIVE = 'LIVE',
}

export type SourceConfig = {
  nextTitle?: string;
  nextThumbnail?: string;
  nextThumbnailAlt?: string;
  separator?: string;
  tagLive?: string;
  startTime?: string;
  endTime?: string;
  dash?: string;
  hls?: string;
  mp4?: string;
  webm?: string;
  drm?: DRMConfig;
  startOffset?: number;
  audios?: Array<AudioConfig>;
  subtitles?: Array<SubtitleConfig>;
  title?: string;
  secondaryTitle?: string;
  streamType?: StreamTypes;
  logo?: {
    type: 'image';
    props: ImageProps;
  };
};

export type AudioConfig = {
  language: string;
  languageName: string;
  label: string;
  original: boolean;
  normalized: boolean;
};

export type SubtitleConfig = {
  id?: string;
  lang: string;
  label: string;
  url?: string;
};

export type DRMProvider = 'AXINOM' | 'DRM_TODAY';

export type DRMConfig = {
  provider?: DRMProvider;
  widevine?: {
    serverUrl: string;
    httpRequestHeaders?: Record<string, string>;
  };
  playready?: {
    serverUrl: string;
    httpRequestHeaders?: Record<string, string>;
  };
  fairplay?: {
    serverUrl: string;
    httpRequestHeaders?: Record<string, string>;
    certificate: string;
  };
};

export type SeekType = 'forward' | 'rewind' | 'scrub';
export interface IPlayerAdapter<T = unknown> {
  startOnFullscreen?: boolean;
  status: PlayerStatus;
  viewMode: PlayerViewMode;
  currentTime?: number;
  totalDuration?: number;
  maxBufferedTime: number;
  muted: boolean;
  mutedByAutoplayFallback?: boolean;
  volume: number;
  seeking: boolean;
  seekType?: SeekType;
  stalling: boolean;
  canHideControls: boolean;
  audioTracks: Array<AudioTrack>;
  subtitleTracks: Array<SubtitleTrack>;
  subtitleCues: Array<CueData>;
  currentAudioId?: string;
  currentSubtitleId?: string;
  isShowingAds: boolean;
  config: BaseConfig;
  containerNode: RefObject<HTMLDivElement>;
  source?: SourceConfig;
  setConfig(config: BaseConfig): void;
  init(container: HTMLElement, adapterConfig: T): void;
  retry(): void;
  destroy(): void;
  load(source: SourceConfig): Promise<void>;
  play(issuer?: IssuerEventType): void;
  restart(): void;
  pause(issuer?: IssuerEventType): void;
  mute(): void;
  unmute(): void;
  timeShift(increment: number): void;
  seek(time: number): void;
  getStatus(): PlayerStatus;
  setSeeking(seeking: boolean): void;
  setViewMode(viewMode: PlayerViewMode): void;
  setVolume(volume: number): void;
  setCurrentTime(time: number): void;
  setCanHideControls(canHideControls: boolean): void;
  restoreAutoplaySound(): void;
  disableSubtitles(): void;
  enableSubtitle(id: string): void;
  enableAudio(id: string): void;
  getStreamType(): string;
  getCurrentAudioLang(): string;
  getCurrentSubtitleLang(): string;
  on<T extends PlayerEvent>(
    event: T,
    callback: PlayerEventCallback<T>,
  ): () => void;
  off<T extends PlayerEvent>(event: T, callback: PlayerEventCallback<T>): void;
}

export type MeliPlayerTranslations = {
  audioAriaLabel: string;
  audioSubtitleSelectorAriaLabel: string;
  availableAudiosTitle: string;
  availableSubtitlesTitle: string;
  closeAriaLabel: string;
  disableSubtitle: string;
  enterFullscreenAriaLabel: string;
  exitFullscreenAriaLabel: string;
  forwardAriaLabel: string;
  loading: string;
  muteAriaLabel: string;
  pauseAriaLabel: string;
  playAriaLabel: string;
  playbackProgressAriaLabel: string;
  rewindAriaLabel: string;
  subtitlesAriaLabel: string;
  togglePlaybackAriaLabel: string;
  unmuteAriaLabel: string;
  volumeAriaLabel: string;
  volumeAutoplayUnmuteLabel: string;
  retryButtonLabel: string;
  errorText: string;
  restartLabel: string;
  posterAltLabel?: string;
};
