import type { FC, PropsWithChildren } from 'react';
import type { PageProps, PageState } from './Page.types';
import type { ErrorProps } from '../../container/ErrorContainer';

import { useEffect } from 'react';
import serialize from 'serialize-javascript';

import GTM from 'nordic/gtm';
import { Script } from 'nordic/script';
import Style from 'nordic/style';
import { Page } from 'nordic/page';
import classNames from 'classnames';
import { AndesProvider } from '@andes/context';
import { ErrorScreen } from '@andes/technical-error';
import { useI18n } from 'nordic/i18n';

import { ProviderTrackEventName, track } from '../../utils/track';
import commons from '../../constants/commons.json';
import { MetaData } from './components/MetaData';
import { PageProvider } from './context/PageContext';
import { ErrorContainer } from '../../container/ErrorContainer';
import { TypeKnownComponent } from '../../../types/components';
import { SplashScreenContainer } from '../SplashScreenContainer';
import ErrorBoundary from '../CustoErrorBoundary/CustomErrorBoundary';

const { MELIDATA_SRC, CONTENT_SEARCH_SRC } = commons;

export const SPAPage: FC<PropsWithChildren<PageProps>> = (props) => {
  const {
    children,
    className,
    device,
    error,
    headerOptions,
    keyword,
    locale,
    name,
    platform,
    translations,
    view,
    view: { tracks, metadata } = {},
    googleTagManager,
    chunkName,
  } = props;
  const { i18n } = useI18n();

  const stateProps = {
    device,
    error,
    headerOptions,
    keyword,
    name,
    platform,
    translations,
    view,
    locale,
    googleTagManager,
    chunkName,
  } satisfies PageState;

  const deviceType = device.mobile ? 'mobile' : 'desktop';

  if (typeof window !== 'undefined') {
    window.__VIEW_DISPLAY_TRACK__ = tracks;
  }

  useEffect(() => {
    if (tracks) {
      track(tracks, ProviderTrackEventName.DISPLAY);
    }
  }, [tracks]);

  useEffect(() => {
    if (window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [view]);

  if (
    Array.isArray(view.components) &&
    view.components.find(({ type }) => type === TypeKnownComponent.ERROR)
  ) {
    const errorProps = view.components.find(
      ({ type }) => type === TypeKnownComponent.ERROR,
    )?.props as ErrorProps;

    return (
      <ErrorContainer
        className="mplay-error"
        components={errorProps.components ?? []}
      />
    );
  }

  if (TypeKnownComponent.ERROR in view.components) {
    return (
      <ErrorContainer
        className={classNames(
          'mplay-error',
          view.components[TypeKnownComponent.ERROR]?.className,
        )}
        components={view.components[TypeKnownComponent.ERROR]?.components ?? []}
      />
    );
  }

  return (
    <Page name={name} className={classNames(className, 'ui-page')}>
      <MetaData {...metadata} />
      <GTM id={googleTagManager?.id} />
      <AndesProvider locale={locale!} device={device}>
        <PageProvider configurations={{ ...view.configurations }}>
          <Style href={`app-${deviceType}.css`} />
          {TypeKnownComponent.SPLASHSCREEN in view.components && (
            <SplashScreenContainer
              data={view.components[TypeKnownComponent.SPLASHSCREEN]!}
              tracks={view.components[TypeKnownComponent.SPLASHSCREEN]!.tracks}
              siteId={platform.siteId}
            />
          )}
          <Script src={MELIDATA_SRC} priority={10} />
          <Script src="framework.js" priority={11} />
          <Script src="vendor.js" priority={11} />
          <Script src="andes.js" priority={11} />

          <Script priority={12}>
            {`
              window.__PRELOADED_STATE__ = ${serialize(stateProps, {
                isJSON: true,
              })};
            `}
          </Script>
          <Script src={`${chunkName}.chunk.js`} priority={13} />
          <Script src={`app-${deviceType}.js`} priority={14} />
          <Script id="search-box" src={CONTENT_SEARCH_SRC} priority={15} />
          <ErrorBoundary
            fallback={
              <ErrorScreen
                action={{
                  text: i18n.gettext('Reintentar'),
                  onClick: () => {
                    window.location.reload();
                  },
                }}
                description={i18n.gettext('Por favor, inténtalo nuevamente.')}
                title={i18n.gettext(
                  'Lo sentimos, no pudimos cargar esta sección.',
                )}
              />
            }
          >
            {children}
          </ErrorBoundary>
        </PageProvider>
      </AndesProvider>
    </Page>
  );
};
