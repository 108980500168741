import type { FC } from 'react';
import type { MediaCardVerticalProps } from '../MediaCardVertical.types';

import Card from '@andes/card';
import classname from 'classnames';

import { Image } from '../../Image/mobile';
import { Link } from '../../Link/Link';
import { PillItemCards } from '../../MediaCard/components/PillItemCards';

const namespace = 'mediacard-vertical';

export const MediaCardVertical: FC<MediaCardVerticalProps> = ({
  size,
  header,
  linkTo,
  carouselType = 'vertical',
}) => {
  const { background, topLeftItem } = header.default;

  if (!background) {
    return null;
  }

  return (
    <Card
      className={classname(namespace, `size--${size}`, `type--${carouselType}`)}
      shadow="elevated"
    >
      <Link to={linkTo} className="poster-media-card__link">
        {topLeftItem && (
          <PillItemCards itemProps={topLeftItem?.props} size="small" />
        )}

        <Image
          carousel
          preload
          lazy="off"
          {...background.props}
          className="poster-media-card__image"
        />
      </Link>
    </Card>
  );
};
