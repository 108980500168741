import { Modal as AndesModal } from '@andes/modal';
import { useI18n } from 'nordic/i18n';
import { Typography } from '@andes/typography';
import { Button } from '@andes/button';

import { useRestriction } from '../../../context/restrictions-store/restriction.store';
import { useFullVCMNavigation } from '../../../@domain/hooks/useFullVCMNavigation';

type ContentLockModalProps = {
  id: string;
};

export const ContentLockModal = ({ id }: ContentLockModalProps) => {
  const { i18n } = useI18n();
  const { navigateToLogin } = useFullVCMNavigation();
  const { isOpenModalLock, setIsOpenModalLock } = useRestriction();

  const handleLogin = (): void => {
    navigateToLogin(id);
  };

  return (
    <AndesModal
      title={i18n.gettext('¿Cómo accedo a ver el contenido exclusivo?')}
      open={isOpenModalLock}
      onClose={() => setIsOpenModalLock(isOpenModalLock)}
      type="card"
      className="modal-content-lock"
    >
      <Typography className="custom-typo-user-guest">
        {i18n.gettext(
          'Para disfrutar el contenido exclusivo de Universal+ Esencial tienes que tener una o mas compras en Mercado Libre por el monto equivalente a {0} los últimos 90 días.',
          '$25.000',
        )}
      </Typography>

      <div className="content-button">
        <Button fullWidth size="large" onClick={handleLogin}>
          {i18n.gettext('Iniciar sesión')}
        </Button>
      </div>
    </AndesModal>
  );
};
