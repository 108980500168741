import type { FC, SVGProps } from 'react';

export const PlayVCMIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 26.7338V6.26619C6 5.4889 6.84797 5.00878 7.5145 5.4087L24.5708 15.6425C25.2182 16.0309 25.2182 16.9691 24.5708 17.3575L7.5145 27.5913C6.84797 27.9912 6 27.5111 6 26.7338Z"
      fill="white"
      stroke="white"
      strokeWidth="1.5"
    />
  </svg>
);
