import { useEffect, useState } from 'react';

import { usePlayer } from '../../MeliPlayer.context';
import { PlayerViewMode } from '../../MeliPlayer.types';
import { useMeliPlayerTranslations } from '../../MeliPlayerTranslations.context';
import { EnterFullscreenIcon } from '../icons/EnterFullscreenIcon';
import { ExitFullscreenIcon } from '../icons/ExitFullscreenIcon';
import { Button } from '../ui/Button';

export const FullscreenButton = () => {
  // Get the current state from the fullscreen
  const initialIsFullscreen = usePlayer(
    ({ viewMode }) => viewMode === PlayerViewMode.FULLSCREEN,
  );

  // Create a local state to control the fullscreen
  const [isFullscreen, setIsFullscreen] = useState(initialIsFullscreen);

  const { enterFullscreenAriaLabel, exitFullscreenAriaLabel } =
    useMeliPlayerTranslations();

  const setViewMode = usePlayer(
    ({ setViewMode: setPlayerViewMode }) => setPlayerViewMode,
  );

  const Icon = isFullscreen ? ExitFullscreenIcon : EnterFullscreenIcon;
  const label = isFullscreen
    ? exitFullscreenAriaLabel
    : enterFullscreenAriaLabel;

  // Effect to sync our local state with the global viewMode
  useEffect(() => {
    if (isFullscreen) {
      setViewMode(PlayerViewMode.FULLSCREEN);
    } else {
      setViewMode(PlayerViewMode.INLINE);
    }
  }, [isFullscreen, setViewMode]);

  const handleClick = () => {
    setIsFullscreen((prev) => !prev);
  };

  return (
    <Button aria-label={label} onClick={handleClick}>
      <Icon />
    </Button>
  );
};
