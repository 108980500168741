import type { TypographyApiProps } from '../../../Typography/desktop';
import type { OptionListComponent } from '../../DialogBox.type';
import type { FC } from 'react';

import Button from '@andes/button';

import { Link } from '../../../Link';
import { Typography } from '../../../Typography/desktop';
import { Icon } from '../../../Icon';
import { Deeplink } from '../../../Deeplink';

const TypographyWrapper = (props: TypographyApiProps) => (
  <Typography type="body" size="s" {...props} />
);

export const OptionList: FC<OptionListComponent> = ({
  props,
  onClickSelectOption,
}) => (
  <section>
    <Icon {...props.symbol.props} />
    {props.action.type === 'delete' && (
      <Button
        hierarchy="transparent"
        size="small"
        fullWidth
        onClick={onClickSelectOption}
      >
        <TypographyWrapper {...props.title.props} />
      </Button>
    )}
    {props.action.type === 'navigation' && !props.action.deeplink && (
      <Link to={props.action.linkTo!}>
        <TypographyWrapper {...props.title.props} />
      </Link>
    )}
    {props.action.type === 'navigation' && props.action.deeplink && (
      <Deeplink
        callback={() => onClickSelectOption({ type: props.action.type })}
      >
        <TypographyWrapper {...props.title.props} />
      </Deeplink>
    )}
  </section>
);
