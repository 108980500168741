import type { AxiosError } from 'nordic-axios';

import axios from 'nordic-axios';

import { ignoreStatusCodes } from '../../../utils/create-rest-client/utils/ignoreStatusCode';
import { getTagsClient } from '../../../utils/create-rest-client/utils/get-tags';

export const trackErrorTraceClient = (error: AxiosError) => {
  if (axios.isCancel(error)) {
    throw error;
  }

  const tags = getTagsClient(error);
  const status = error.response?.status;

  // Ignore certain status codes
  if (status && ignoreStatusCodes.has(status)) {
    throw error;
  }

  if (window.newrelic && typeof window.newrelic?.noticeError === 'function') {
    window.newrelic?.noticeError(error, tags);
  }

  throw error;
};
