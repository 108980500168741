import { useEffect } from 'react';

import {
  CUSTOM_NATIVE_EVENTS,
  BACK_EVENTS_HUB_UPDATE,
} from '../../../utils/webkit/custom-native-events';
import { isWebKit2 } from '../../../utils/webkit/check-version';
import { backEventHandlerWebKit2 } from '../../../utils/webkit/back-event';

const { USER_EVENT } = CUSTOM_NATIVE_EVENTS;

export const backEventHandlerWebKit1 = (evt: unknown, callback: () => void) => {
  // @ts-expect-error: not exist type to evt
  if (BACK_EVENTS_HUB_UPDATE.includes(evt.args.action)) {
    callback();
  }
};

export const useSubscribeToBackEvent = (
  updateCallback: () => void,
  events: Array<string> = BACK_EVENTS_HUB_UPDATE,
) => {
  useEffect(() => {
    if (isWebKit2()) {
      window.MobileWebKit?.events.subscribe(USER_EVENT, (event) =>
        backEventHandlerWebKit2({
          event,
          callbacks: { secondCallback: updateCallback },
          backEvents: events,
        }),
      );
    } else {
      window.MobileWebKit?.on('native_request', (evt: unknown) =>
        backEventHandlerWebKit1(evt, updateCallback),
      );
    }
  }, [updateCallback]);
};
